import { equal } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { Branch } from '@/client/branches';
import { SendTestEmailFormValues } from '@/client/campaigns';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { Group } from '@/client/groups';
import { SecurityCultureSendSurveyFormValues } from '@/client/security-culture/types';
import { Subjects } from '@/client/users';
import { LoadingStatuses } from '@/common/constants';
import { TargetEntity } from '@/common/types/targets.type';
import { FormikInput, FormikMultiSelect } from '@/components/form';
import {
  useBranchesPartialRequest,
  useGroupsPartialRequest,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import { FlexContainer, FormContainer } from '@/ui/styled-ui';
import { formatTargetEntities } from '@/utils/targets';
import { Field, Form, Formik } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SendTestEmailModal } from '../campaigns';
import { securityCultureSchema } from './validations';

type SecurityCultureSendSurveyFormProps = {
  onSubmit: (data: SecurityCultureSendSurveyFormValues) => void;
  onSendTestEmail: (
    data: SecurityCultureSendSurveyFormValues,
    test: SendTestEmailFormValues,
  ) => void;
  account: Account;
  loading: LoadingStatuses;
};

export const SecurityCultureSendSurveyForm: React.FC<
  SecurityCultureSendSurveyFormProps
> = ({ onSubmit, onSendTestEmail, loading }) => {
  const { t } = useTranslation();
  const { canUseFeature } = useFeatureFlag();
  const currentAccount = useAppSelector(selectCurrentAccount);
  const currentUser = useAppSelector(selectCurrentUser);
  const [testEmail, setTestEmail] = useState(false);
  const [initialValues] = useState<SecurityCultureSendSurveyFormValues>({
    targets: [],
    typeFormId: '',
  });

  const groupedEntities = useRef<
    {
      label: string;
      items: TargetEntity[];
    }[]
  >([]);

  const { branches, isLoading: isBranchesLoading } = useBranchesPartialRequest({
    accountId: currentAccount?.id,
    filters: [equal('active', true)],
    sort: ['name,asc'],
    withDescendants: true,
    take: 20,
  });

  const { groups, isLoading: isGroupsLoading } = useGroupsPartialRequest({
    accountId: currentAccount?.id,
    filters: [equal('active', true)],
    sort: ['name,asc'],
  });

  useEffect(() => {
    if (currentAccount && !isBranchesLoading && !isGroupsLoading) {
      const entities = [];

      entities.push({
        label: t('account'),
        items: [currentAccount],
      });

      if (branches?.length) {
        entities.push({
          label: t('branches'),
          items: formatTargetEntities(
            branches,
            null,
            CourseEntityEnrollEnum.BRANCHES,
          ),
        });
      }

      if (groups?.length) {
        entities.push({
          label: t('groups'),
          items: formatTargetEntities(
            groups,
            null,
            CourseEntityEnrollEnum.GROUPS,
          ),
        });
      }

      groupedEntities.current = entities;
    }
  }, [isBranchesLoading, isGroupsLoading]);

  const checkIsOptionDisabled = (
    targets: (Account | Branch | Group)[],
    option: Account | Branch | Group,
  ) => {
    if (
      targets.find(
        (entity: Account | Branch | Group) => entity.id === currentAccount?.id,
      ) &&
      option.id !== currentAccount?.id
    )
      return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={securityCultureSchema(
        t,
        canUseFeature(Subjects.SECURITY_CULTURE_TYPEFORM_INTEGRATION),
      )}
      validateOnMount
      onSubmit={(data) => onSubmit(data)}
    >
      {({ setFieldValue, values, errors }) => {
        return (
          <FormContainer>
            <Form className="w-full">
              <div className="field w-full">
                <Field
                  id="targets"
                  name="targets"
                  dropdownIcon={
                    isBranchesLoading || isGroupsLoading
                      ? 'pi pi-spinner pi-spin'
                      : 'pi pi-chevron-down'
                  }
                  filter
                  label={t('generic.recipients')}
                  className="w-full"
                  display="chip"
                  placeholder={t('generic.select')}
                  required
                  optionDisabled={(option: Account | Branch | Group) =>
                    checkIsOptionDisabled(values.targets || [], option)
                  }
                  onChange={(event: DropdownChangeEvent) => {
                    setFieldValue('targets', event.value);
                  }}
                  dataKey="id"
                  optionLabel="name"
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                  component={FormikMultiSelect}
                  options={groupedEntities.current}
                  disabled={isBranchesLoading || isGroupsLoading}
                />
              </div>
              {canUseFeature(Subjects.SECURITY_CULTURE_TYPEFORM_INTEGRATION) ? (
                <div className="field w-full mb-4">
                  <Field
                    id="typeFormId"
                    name="typeFormId"
                    label={t('security.culture.typeFormId')}
                    className="w-full"
                    component={FormikInput}
                    disabled={loading === LoadingStatuses.LOADING}
                  />
                </div>
              ) : (
                <div className="field w-full mb-4">
                  <Field
                    id="url"
                    name="url"
                    label={t('security.culture.surveyUrl')}
                    className="w-full"
                    component={FormikInput}
                    disabled={loading === LoadingStatuses.LOADING}
                    required
                  />
                </div>
              )}
              <FlexContainer
                justify="space-between"
                className="mt-5"
                wrap="wrap"
                gap={12}
              >
                <AppButton
                  label={t('camapign.testEmail.send')}
                  severity="secondary"
                  type="outlined"
                  onClick={() => setTestEmail(true)}
                  state={loading}
                />
                <AppButton
                  label={t('security.culture.sendSurveyTarget')}
                  isSubmit
                  isDisabled={!!Object.keys(errors).length}
                  state={loading}
                />
              </FlexContainer>

              <SendTestEmailModal
                email={currentUser?.email as string}
                visible={testEmail}
                onSubmit={(test: SendTestEmailFormValues) => {
                  onSendTestEmail(values, test);
                  setTestEmail(false);
                }}
                onHide={() => setTestEmail(false)}
                extended={false}
                withName
              />
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};
