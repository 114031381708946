import { CourseStatusAttemptEnum } from '@/client/courses';
import { ScormCMICompletionStatusEnum } from '@/client/learner-course';
import { User } from '@/client/users';
import 'scorm-again';
import { v4 } from 'uuid';
import { ScormVersion, TScormFactory, TScormFactoryParams } from './types';

const ignoredErrorCodes = ['0', '406'];

const scorm12Factory: TScormFactory = (
  user,
  { onSetValue, onCommit, onInit, onError },
) => {
  (window as any).API = new (window as any).Scorm12API({
    autoCommit: true,
  });

  const api = (window as any).API;
  api.cmi.core.student_id = user.id;
  api.cmi.core.student_name = user.name;

  const listenForErrors = () => {
    if (onError) {
      const errorCode = api.LMSGetLastError();
      if (!ignoredErrorCodes.includes(errorCode)) {
        // Returns a short string describing the specified error code.
        onError(api.LMSGetErrorString(errorCode));
      }
    }
  };

  if (onInit) {
    onInit(api);
    listenForErrors();
  }

  if (onSetValue) {
    api.on('LMSSetValue.cmi.*', (element: string, value: any) => {
      onSetValue(element, value, api);
      listenForErrors();
    });
  }

  if (onCommit) {
    api.on('LMSCommit', () => {
      onCommit(
        api || {},
        parseAttemptStatusFromCMI(api?.cmi?.core, 'lesson_status'),
        parseTotalTimeStringToMinutesNumber(api?.cmi?.total_time),
        Number(api?.cmi?.core?.score?.raw || 0),
      );
      listenForErrors();
    });
  }

  const destroy = () => {
    if (onSetValue) api.off('LMSSetValue.cmi.*');
    if (onCommit) api.off('LMSCommit');
  };

  return {
    api,
    destroy,
  };
};

const scorm2004Factory: TScormFactory = (
  user,
  { onInit, onCommit, onSetValue, onError },
) => {
  (window as any).API_1484_11 = new (window as any).Scorm2004API({
    autoCommit: true,
  });

  const api = (window as any).API_1484_11;
  api.cmi.learner_id = user.id;
  api.cmi.learner_name = user.name;

  const listenForErrors = () => {
    if (onError) {
      const errorCode = api.getLastError();
      if (!ignoredErrorCodes.includes(errorCode)) {
        // Returns a short string describing the specified error code.
        onError(api.GetErrorString(errorCode));
      }
    }
  };

  if (onInit) {
    onInit(api);
    listenForErrors();
  }

  if (onSetValue) {
    api.on('SetValue.cmi.*', (element: string, value: any) => {
      onSetValue(element, value, api);
      listenForErrors();
    });
  }

  if (onCommit) {
    api.on('Commit', () => {
      onCommit(
        api || {},
        parseAttemptStatusFromCMI(api?.cmi, 'completion_status'),
        parseTotalTimeStringToMinutesNumber(api?.cmi?.total_time),
        Number(api?.cmi?.score?.raw || 0),
      );
      listenForErrors();
    });
  }

  const destroy = () => {
    if (onSetValue) api.off('SetValue.cmi.*');
    if (onCommit) api.off('Commit');
  };

  return {
    api,
    destroy,
  };
};

const parseAttemptStatusFromCMI = (statusObject: any, statusField: string) => {
  if (
    statusObject &&
    (statusObject[statusField] === ScormCMICompletionStatusEnum.COMPLETED ||
      statusObject[statusField] === ScormCMICompletionStatusEnum.PASSED)
  ) {
    return CourseStatusAttemptEnum.COMPLETED;
  }

  return CourseStatusAttemptEnum.IN_PROGRESS;
};

// timeString is HHHH:MM:SS.SS (CMITimespan)
const parseTotalTimeStringToMinutesNumber = (timeString: string) => {
  if (!timeString) {
    return 0;
  }

  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  const totalMinutes = hours * 60 + minutes + seconds / 60;

  return Math.floor(totalMinutes);
};

export const scormFactory = (
  user: User,
  version: ScormVersion,
  params: TScormFactoryParams,
) => {
  switch (version) {
    default:
    case 'scorm-v3':
      return scorm12Factory(user, params);
    case 'scorm-v4':
      return scorm2004Factory(user, params);
  }
};
