import { CampaignBenchmark } from '@/client/campaigns';
import { Subjects } from '@/client/users';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { DoughnutChart } from '@/ui/charts/doughnut-chart';
import { FlexContainer } from '@/ui/styled-ui';
import { colorRedLight } from '@/utils/helpers/css-variables.helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledUsersScore = styled(FlexContainer)`
  padding-inline: var(--medium-padding);
  justify-content: flex-start;
  flex-wrap: wrap;

  .charts {
    width: max-content;
    flex-direction: column;
  }

  label {
    text-align: center;
    width: 100%;
    padding-top: var(--default-padding);
    padding-bottom: 0;
    margin: 0 !important;
    font-size: var(--small-font-size);
    line-height: var(--small-line-height);
  }
`;

export type UsersScorePropsType = {
  benchmark: CampaignBenchmark;
};

export const UsersScore: React.FC<UsersScorePropsType> = ({ benchmark }) => {
  const { t } = useTranslation();
  const { canUseFeature } = useFeatureFlag();

  return (
    <>
      <StyledUsersScore className="mt-4" gap={40}>
        <FlexContainer className="charts">
          <DoughnutChart
            allCount={100}
            singleCount={benchmark?.clickedLinksPercentage ?? 0}
            id="clickedLinksPercentage"
            label={t('campaign.clicked')}
            cutout="70%"
            width="120px"
            mainColor={colorRedLight}
            isPercentCount
            isDashboard
          />
          <label htmlFor="clickedLinksPercentage">
            {t('campaign.clicked')}
          </label>
        </FlexContainer>
        <FlexContainer className="charts">
          <DoughnutChart
            allCount={100}
            singleCount={benchmark?.submittedDataPercentage ?? 0}
            id="submittedDataPercentage"
            label={t('campaign.submitted')}
            cutout="70%"
            width="120px"
            mainColor={colorRedLight}
            isPercentCount
            isDashboard
          />
          <label htmlFor="submittedDataPercentage">
            {t('campaign.submitted')}
          </label>
        </FlexContainer>
        {canUseFeature(Subjects.OUTLOOK_PHISHING_EMAIL_REPORTING) && (
          <FlexContainer className="charts">
            <DoughnutChart
              allCount={100}
              singleCount={benchmark?.emailsReportedPercentage ?? 0}
              id="reportedDataPercentage"
              label={t('campaign.email.reported')}
              cutout="70%"
              width="120px"
              mainColor={colorRedLight}
              isPercentCount
              isDashboard
            />
            <label htmlFor="reportedDataPercentage">
              {t('campaign.email.reported')}
            </label>
          </FlexContainer>
        )}
      </StyledUsersScore>
    </>
  );
};
