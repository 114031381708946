import { ListingRequest, ListingResponse } from '@/api/types';
import { Account, AccountSettings, AccountTypeEnum } from '@/client/accounts';
import { Reseller } from '@/client/resellers';
import { User } from '@/client/users';

export type ListAccountsRequest = ListingRequest;
export type ListAccountsResponse = ListingResponse<Account>;

export type SubdomainAccountIdResponse = {
  id: string;
};

export type AccountSAMLConfigReadOnly = {
  identityProviderUrl?: string;
  certificateFingerprint?: string;
  remoteSigninUrl?: string;
  remoteSignoutUrl?: string;
  targetedIdIdentifier?: string;
  firstNameIdentifier?: string;
  lastNameIdentifier?: string;
  emailIdentifier?: string;
};

export type AccountFormState = {
  id?: string;
  name: string;
  subdomain?: string;
  active?: boolean;
  eFrontSync?: boolean;
  isMigratedOutsideOfEFront?: boolean;
  autoCourseAvailability?: boolean;
  isSSO?: boolean;
  isPhishingOnly?: boolean;
  isFreeTrial?: boolean;
  reseller: Reseller | null;
  mainAccountOfReseller: Reseller | null;
  defaultLanguage: string | null;
  type: boolean;
  hubspotId: string;
  hubspotDomains: string[];
  lifeCycleStage?: string;
  awtStatus?: string;
  phishingStatus?: string;
  companyOwner?: string;
  courseDelivery?: AccountCourseDelivery;
  courseDueDaysCheck?: boolean;
  courseDueDays?: number | null;
  notifyManagerForOverdueCourses?: boolean;
  courseMinimumScore?: number | null;
};

export type SaveAccountRequest = {
  name: string;
  subdomain?: string;
  active?: boolean;
  eFrontSync?: boolean;
  isMigratedOutsideOfEFront?: boolean;
  autoCourseAvailability?: boolean;
  isSSO?: boolean;
  isPhishingOnly?: boolean;
  isFreeTrial?: boolean;
  reseller?: string | null;
  defaultLanguage?: string | null;
  type?: AccountTypeEnum | null;
  hubspotId?: string;
  courseDelivery?: AccountCourseDelivery;
  courseDueDays?: number | null;
  courseMinimumScore?: number | null;
};

export type UpdateAccount = {
  name?: string;
  active?: boolean;
  description?: string;
  eFrontSync?: boolean;
  isMigratedOutsideOfEFront?: boolean;
  settings?: AccountSettings;
  autoCourseAvailability?: boolean;
  isSSO?: boolean;
  isPhishingOnly?: boolean;
  isFreeTrial?: boolean;
  reseller?: string | null;
  defaultLanguage?: string | null;
  type?: AccountTypeEnum | null;
  hubspotId?: string;
  hubspotPrimaryDomain?: string;
  hubspotAdditionalDomains?: string[];
  courseDelivery?: AccountCourseDelivery;
  courseDueDays?: number | null;
};

export type UpdateAccountRequest = {
  accountId: string;
  updates: UpdateAccount;
};

export type PostUserRequest = {
  users: string[];
};

export type AccountSendTestEmailRequest = {
  accountId: string;
  email: string;
  notifyId: string;
};

export type ImportAccountUsersRequest = {
  accountId: string;
  fileId: string;
  importKey: string;
};

export type ImportSSOConfigRequest = {
  accountId: string;
  displayName?: string;
  importUrl?: string;
  isSSO?: boolean;
};

export type UpdateSSOConfigRequest = {
  accountId: string;
  displayName?: string;
  idpEntityId?: string;
  singleSignOnServiceUrl?: string;
  signingCertificate?: string;
  isSSO?: boolean;
};

export type ListDuplicatedUsersRequest = ListingRequest;
export type ListDuplicatedUsersResponse = ListingResponse<DuplicatedUsersRow>;

export type DuplicatedUsersRow = {
  email: string;
  users: User[];
};

export type MigrateDuplicatedUsersRequest = {
  accountId: string;
  payload: MigrateDuplicatedUsersPayload;
};

export type MigrateDuplicatedUsersPayload = {
  emails: string[];
};

export type SendHisoricalDataFormValues = {
  startDate?: Date;
  endDate?: Date;
};

export enum AccountCourseDelivery {
  CP_APP = 'cp-app',
  THIRD_PARTY = 'third-party',
  VIDENBAROMETER = 'videnbarometer',
}

export type UpdateAccountAPIClientRequest = {
  active: boolean;
  regenerate: boolean;
};
