import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useEffect } from 'react';
import { useAppSelector } from './store';

const env_check = !!process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

export const useMixpanel = () => {
  const currentUser = useAppSelector(selectCurrentUser);

  useEffect(() => {
    // you can add extra initialization logic here
  }, []);

  const formatUserAdditionalProperties = {
    'User role': currentUser?.role.name,
    'User account type': currentUser?.account.isFreeTrial
      ? 'Free trial'
      : currentUser?.account?.isPhishingOnly
      ? 'Phishing only'
      : 'AWT',
    'Free trial end date': moment
      .utc(currentUser?.freeTrialEndsAt)
      .format(DateFormats.DATEONLY_FULL_MONTH),
    'AD sync enabled': currentUser?.account?.activeDirectoryIntegration?.active,
    'SSO enabled': !!currentUser?.account?.meta?.isSSO,
  };

  const identify = (id: string) => {
    if (env_check && typeof mixpanel !== 'undefined') {
      mixpanel.identify(id);
    }
  };

  const alias = (id: string) => {
    if (env_check && typeof mixpanel !== 'undefined') {
      mixpanel.alias(id);
    }
  };

  const track = (name: string, props?: any) => {
    if (env_check && typeof mixpanel !== 'undefined') {
      mixpanel.track(name, { ...props, ...formatUserAdditionalProperties });
    }
  };

  const people = {
    set: (props: any) => {
      if (env_check && typeof mixpanel !== 'undefined') {
        mixpanel.people.set(props);
      }
    },
  };

  const reset = () => {
    if (
      env_check &&
      typeof mixpanel !== 'undefined' &&
      (mixpanel as any)?.__loaded
    ) {
      mixpanel.reset();
    }
  };

  return { identify, alias, track, people, reset };
};
