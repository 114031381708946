export enum Subjects {
  ALL = 'all',
  DEV_TOOLS = 'dev-tools',
  ACCOUNTS = 'accounts',
  OWN_ACCOUNT = 'own-account', // This is a special case for the account admin
  ASSIGNED_ACCOUNTS = 'assigned-accounts', // This is a special case for the multiple accounts admin
  RESELLERS = 'resellers',
  RESELLERS_DOMAIN_CONFIG = 'resellers-domain-config',
  USERS = 'users',
  ROLES = 'roles',
  GROUPS = 'groups',
  BRANCHES = 'branches',
  AD_SYNC = 'ad-sync',
  FILES = 'files',
  EFRONT_SYNC = 'efront-sync',
  EMAIL_TEMPLATES = 'email-templates',
  CAMPAIGNS = 'campaigns',
  CAMPAIGNS_MIGRATION = 'campaigns-migration',
  SENDING_PROFILES = 'sending-profiles',
  PAGES = 'pages',
  ACCOUNT_CAMPAIGNS_SUMMARY = 'account-campaigns-summary',
  CAMPAIGN_RESULTS = 'campaign-results',
  ADMIN_DASHBOARD = 'admin-dashboard',
  LEARNER_DASHBOARD = 'learner-dashboard',
  SYSTEM_SETTINGS = 'system-settings',
  ME = 'me',
  COURSES = 'courses',
  ENROLLED_COURSES = 'enrolled-courses',
  ATTEMPTS = 'attempts',
  OWN_ATTEMPTS = 'own-attempts',
  COURSE_ACCOUNT_AVAILABILITY = 'course-account-availability',
  COURSE_ACCOUNT_SCHEDULES = 'course-account-schedules',
  COURSE_BRANCH_SCHEDULES = 'course-branch-schedules',
  COURSE_USER_SCHEDULES = 'course-user-schedules',
  ACCOUNT_REPORTS = 'account-reports',
  ACCOUNT_MIGRATION = 'account-migration',
  ACCOUNT_SSO = 'account-sso',
  USERS_MIGRATION = 'users-migration',
  HUBSPOT_ACCOUNT_LINK = 'hubspot-account-link',
  COURSE_GROUP_SCHEDULES = 'course-group-schedules',
  COURSE_FORCE_COMPLETE = 'course-force-complete',
  COURSE_PLANNER = 'course-planner',
  SECURITY_CULTURE = 'security-culture',
  TEST_EMAIL = 'test-email',
  FEATURE_FLAGS = 'feature-flags',
  ACCOUNT_FEATURE_FLAGS = 'account-feature-flags',

  // Feature
  TRAINING_PLANNER = 'training-planner',
  OUTLOOK_PHISHING_EMAIL_REPORTING = 'outlook-phishing-email-reporting',
  EVENT_TRACKING = 'event-tracking',
  PHISHING_CAMPAIGN_USER_TAGGING = 'phishing-campaign-user-tagging',
  EMAIL_OPEN_EVENTS = 'email-open-events',
  PHISHING_CAMPAIGN_TARGET_SELECTOR = 'phishing-campaign-target-selector',
  CUSTOM_COURSE_CREATION = 'custom-course-creation',
  CAMPAIGNS_CATALOGUE = 'campaigns-catalogue',
  PHISHING_BENCHMARK = 'phishing-benchmark',
  REPORT_API = 'report-api',
  SKIP_COURSE_SURVEY = 'skip-course-survey',
  COURSE_MINIMUM_SCORE_REQUIREMENT = 'course-minimum-score-requirement',
  MULTIPLE_FILE_COURSES = 'multiple-file-courses',
  TRY_FOR_FREE = 'try-for-free',
  SECURITY_CULTURE_TYPEFORM_INTEGRATION = 'security-culture-typeform-integration',
}
