import { AppCalendar } from '@/ui/calendar';
import classNames from 'classnames';
import { get } from 'lodash';
import { CalendarProps } from 'primereact/calendar';
import React from 'react';

export type FormikCalendarProps = {
  field: {
    name: string;
    value: any;
  };
  form: {
    touched: Record<string, any>;
    errors: Record<string, any>;
  };
  label?: string;
};

export const FormikCalendar: React.FC<FormikCalendarProps & CalendarProps> = ({
  field,
  form,
  label,
  required,
  className,
  ...rest
}) => {
  const fieldError = get(form.errors, field.name);
  const fieldTouched = get(form.touched, field.name);

  return (
    <>
      <label
        htmlFor={field.name}
        className={classNames({
          'p-error': fieldTouched && !!fieldError,
        })}
      >
        {label}
        {required ? <span className="red"> *</span> : ''}
      </label>
      <AppCalendar
        {...field}
        className={className}
        showIcon
        iconPos="left"
        minDate={new Date()}
        readOnlyInput
        {...rest}
      />
      {fieldTouched && !!fieldError ? (
        <small className="p-error">{fieldError}</small>
      ) : null}
    </>
  );
};
