import { EmailSendTime, EmailType } from '@/client/system-emails';
import { TranslationFunctionType } from '@/common/types';

export const emailTypeOptions = (t: TranslationFunctionType) => [
  {
    label: t('generic.welcome'),
    value: EmailType.WELCOME,
  },
  {
    label: t('account.course.available'),
    value: EmailType.COURSE_AVAILABILITY,
  },
  {
    label: t('account.course.incompleteCourseReminder'),
    value: EmailType.INCOMPLETE_COURSE_REMINDER,
  },
  {
    label: t('account.course.courseEnrollment'),
    value: EmailType.COURSE_ENROLLMENT,
  },
  {
    label: t('account.migration'),
    value: EmailType.ACCOUNT_MIGRATION,
  },
  {
    label: t('generic.welcome.sso'),
    value: EmailType.WELCOME_SSO,
  },
  {
    label: t('account.migration.sso'),
    value: EmailType.ACCOUNT_MIGRATION_SSO,
  },
  {
    label: t('account.welcome.freeTrial'),
    value: EmailType.WELCOME_FREE_TRIAL,
  },
  {
    label: t('security.culture'),
    value: EmailType.SECURITY_CULTURE,
  },
  {
    label: t('security.culture.reminder'),
    value: EmailType.SECURITY_CULTURE_REMINDER,
  },
];

export const getEmailTypesLabels = (
  t: TranslationFunctionType,
): { [key: string]: string } => ({
  [EmailType.WELCOME]: t('generic.welcome'),
  [EmailType.COURSE_AVAILABILITY]: t('account.course.available'),
  [EmailType.INCOMPLETE_COURSE_REMINDER]: t(
    'account.course.incompleteCourseReminder',
  ),
  [EmailType.COURSE_ENROLLMENT]: t('account.course.courseEnrollment'),
  [EmailType.ACCOUNT_MIGRATION]: t('account.migration'),
  [EmailType.WELCOME_SSO]: t('generic.welcome.sso'),
  [EmailType.ACCOUNT_MIGRATION_SSO]: t('account.migration.sso'),
  [EmailType.WELCOME_FREE_TRIAL]: t('account.welcome.freeTrial'),
  [EmailType.SECURITY_CULTURE]: t('security.culture'),
  [EmailType.SECURITY_CULTURE_REMINDER]: t('security.culture.reminder'),
});

export const emailSendTimeOptions = (t: TranslationFunctionType) => [
  {
    label: t('system.email.type.config.userRegistration'),
    value: EmailSendTime.USER_REGISTRATION,
  },
  // {
  //   label: t('system.email.type.config.userRequest'),
  //   value: EmailSendTime.USER_REQUEST,
  // },
  {
    label: t('system.email.type.config.userEnrollment'),
    value: EmailSendTime.USER_ENROLLMENT,
  },
];
