import { FilePurpose, LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import {
  CourseFileModule,
  CourseFilesType,
  CourseStatusAttemptEnum,
} from '@/client/courses';
import { FileExtensions, FileType } from '@/client/files';
import { CourseLanguageChanger } from '@/components/courses/CourseLanguageChanger';
import { CourseLanguageSelectScreen } from '@/components/courses/CourseLanguageSelectScreen';
import { useNotifications } from '@/hooks/notifications.hook';
import { useCourse } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { ScormProvider } from '@/scorm';
import { ScormVersion } from '@/scorm/types';
import { selectCurrentUser } from '@/store/features/users';
import { AppButton } from '@/ui/buttons';
import ScormPDFViewer from '@/ui/pdf-viewer/ScormPDFViewer';
import { FlexContainer } from '@/ui/styled-ui';
import {
  filterFilesByLanguageAndExtension,
  getFileType,
  isUserLanguageAvailableInCourse,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CoursesActionHeader } from './CoursesActionHeader';
import { CoursesTabs } from './CoursesTabs';

const StyledGrayFlexContainer = styled(FlexContainer)`
  border: 1px solid var(--gray-dark);
  border-radius: var(--xsmall-border-radius);
  background-color: var(--beige-main);
  padding: var(--default-padding);
`;

const StyledContentContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  width: 70%;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    display: block;
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
`;

const StyledVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
`;

const StyledNextButton = styled(AppButton)`
  position: absolute;
  right: -48px;
  top: 50%;
`;

export const CourseScormPreviewPage: React.FC = () => {
  const [isScormStarted, setIsScormStarted] = useState<boolean>(false);
  const currentUser = useAppSelector(selectCurrentUser);
  const { t } = useTranslation();
  const toast = useToast();
  const { id: courseId } = useParams();
  const { course, isLoading, refetch } = useCourse({
    courseId: courseId,
    staleTime: Infinity,
  });
  const [isDecompressingFiles, setIsDecompressingFiles] =
    useState<boolean>(false);

  const [status, setStatus] = useState<CourseStatusAttemptEnum>();
  const [modules, setModules] = useState<CourseFileModule[]>([]);
  const [currentModule, setCurrentModule] = useState<number>(0);

  const [selectedLanguage, setSelectedLanguage] = useState<LanguagesEnum>(
    // Set initial preselected language if not available set to English
    currentUser?.language &&
      course &&
      isUserLanguageAvailableInCourse(
        course?.courseFiles,
        currentUser?.language as LanguagesEnum,
      )
      ? (currentUser?.language as LanguagesEnum)
      : LanguagesEnum.EN,
  );

  const getLanguage = () => {
    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      return course?.courseLanguages[0];
    }

    if (course && !course?.selfHosted) {
      return LanguagesEnum.EN;
    }

    return selectedLanguage;
  };

  const [isUrlLoading, setIsUrlLoading] = useState<boolean>(false);

  const checkAndFetchScormUrl = async () => {
    const courseLanguage = getLanguage();

    if (
      course?.courseFiles &&
      Object.values(course?.courseFiles).some(
        ({ fileMeta, file }: CourseFilesType) =>
          file?.purpose === FilePurpose.COURSE && !fileMeta?.dispatchFile,
      )
    ) {
      setIsDecompressingFiles(true);
      setCourseId(courseId);
      // Wait for a notify connection to establish
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (isConnected.current) {
            clearInterval(intervalId);
            resolve(true);
          }
        }, 100);
      });
    } else if (course && courseLanguage && !isUrlLoading) {
      try {
        setIsUrlLoading(true);
        const materials = filterFilesByLanguageAndExtension(
          course.courseFiles,
          courseLanguage,
          [...FileExtensions.PDF, ...FileExtensions.VIDEO],
        );

        const scormFiles = filterFilesByLanguageAndExtension(
          course.courseFiles,
          courseLanguage,
          FileExtensions.SCORM,
        );

        const scorms = await Promise.all(
          scormFiles.map(async (file) => {
            if (!file?.id) return file;

            const scormDetails = await client.courses.getCourseUrl(
              course.id,
              file?.id,
            );
            return { ...file, scormDetails };
          }),
        );

        // Combine materials and scorms and sort by priority
        const modules = [...materials, ...scorms]
          .sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
          .map((module) => ({
            ...module,
            type: getFileType(module.file?.extension),
          }));

        setModules(modules);
      } catch (e) {
        handleAxiosError(e as Error | AxiosError, toast);
      } finally {
        setIsUrlLoading(false);
      }
    }
  };

  const {
    lastMessage: notifications,
    setNotificationParam: setCourseId,
    notificationParam: notificationCourseId,
    isConnected,
  } = useNotifications(client.courses.coursesNotifyUrl);

  useEffect(() => {
    if (!notificationCourseId) return;
    handleCourseNotification(notifications);
  }, [JSON.stringify(notifications)]);

  const handleCourseNotification = async (messages: any) => {
    if (
      !messages?.data?.event ||
      !['course.files.decompressed'].includes(messages?.data?.event)
    )
      return;

    if (messages?.data.success) {
      setIsDecompressingFiles(false);
      refetch();
      checkAndFetchScormUrl();
    }

    if (!messages?.data?.success && messages?.data?.error) {
      toast?.error(t('toast.error'), messages?.data?.error, 5000);
    }

    setCourseId(undefined);
    setIsDecompressingFiles(false);
  };

  useEffect(() => {
    if (!course || isLoading || isUrlLoading) return;

    if (course?.selfHosted && course?.courseLanguages?.length === 1) {
      setIsScormStarted(true);
    }
  }, [course]);

  useEffect(() => {
    if (!course || isLoading || isUrlLoading) return;

    if (course?.courseFiles?.length) {
      checkAndFetchScormUrl();
      setCurrentModule(0);
    }
  }, [course?.id, selectedLanguage]);

  return (
    <>
      {isLoading && !course && (
        <FlexContainer direction="column" className="mt-5">
          <ProgressSpinner />
          <h3>{t('course.content.loading')}</h3>
        </FlexContainer>
      )}
      {!isLoading && course && (
        <FlexContainer
          direction="column"
          align="flex-start"
          justify="flex-start"
          height="100%"
        >
          <CoursesActionHeader course={course} />
          {courseId && <CoursesTabs courseId={courseId} type={course.type} />}

          {isScormStarted &&
            course.selfHosted &&
            course?.courseLanguages?.length > 1 && (
              <FlexContainer justify="flex-end" className="mb-3">
                <CourseLanguageChanger
                  language={selectedLanguage}
                  course={course}
                  shouldShowWarning={true}
                  onChange={(value) => setSelectedLanguage(value)}
                />
              </FlexContainer>
            )}
          {/* VIDEO */}
          {isScormStarted &&
            !isDecompressingFiles &&
            modules?.[currentModule]?.file &&
            modules?.[currentModule]?.type === FileType.VIDEO && (
              <StyledContentContainer>
                <StyledVideo
                  src={modules?.[currentModule]?.file?.signedUrl}
                  controls
                  autoPlay
                  onEnded={() =>
                    currentModule !== modules.length - 1 &&
                    setCurrentModule(currentModule + 1)
                  }
                />
              </StyledContentContainer>
            )}

          {/* PDF */}
          {isScormStarted &&
            !isDecompressingFiles &&
            modules?.[currentModule]?.file &&
            modules?.[currentModule]?.type === FileType.PDF && (
              <ScormPDFViewer
                file={modules[currentModule].file}
                hideNextButton={currentModule === modules.length - 1}
                onComplete={() => setCurrentModule(currentModule + 1)}
              />
            )}

          {(isScormStarted || !course.selfHosted) &&
            !isUrlLoading &&
            !!course?.courseFiles?.length &&
            !isDecompressingFiles &&
            !!modules?.length &&
            modules?.[currentModule]?.scormDetails &&
            modules?.[currentModule]?.type === FileType.SCORM &&
            currentUser && (
              <FlexContainer direction="column">
                <StyledContentContainer>
                  <ScormProvider
                    course={course}
                    version={
                      modules?.[currentModule]?.scormDetails
                        ?.version as ScormVersion
                    }
                    user={currentUser}
                    isPreview={true}
                    setStatus={setStatus}
                  >
                    <StyledIframe
                      src={modules?.[currentModule]?.scormDetails?.url}
                    />
                  </ScormProvider>
                  {status === CourseStatusAttemptEnum.COMPLETED &&
                    currentModule !== modules.length - 1 && (
                      <StyledNextButton
                        size="xs"
                        icon="pi pi-chevron-right"
                        onClick={() => setCurrentModule(currentModule + 1)}
                      />
                    )}
                </StyledContentContainer>
              </FlexContainer>
            )}
          {!isScormStarted &&
            course.selfHosted &&
            !!course?.courseFiles?.length &&
            !isDecompressingFiles && (
              <CourseLanguageSelectScreen
                course={course}
                language={selectedLanguage}
                isLoading={isUrlLoading}
                isDisabled={!modules?.length}
                setIsCourseStarted={setIsScormStarted}
                setSelectedLanguage={setSelectedLanguage}
              />
            )}
          {course && !course?.courseFiles?.length && !isDecompressingFiles && (
            <StyledGrayFlexContainer direction="column" flex="1" height="100%">
              <h3>{t('course.scorm.preview.noFiles')}</h3>
            </StyledGrayFlexContainer>
          )}
          {course && isDecompressingFiles && (
            <StyledGrayFlexContainer direction="column" flex="1" height="100%">
              <ProgressSpinner />
              <h3>{t('course.scorm.preview.decompressing')}</h3>
            </StyledGrayFlexContainer>
          )}
        </FlexContainer>
      )}
    </>
  );
};
