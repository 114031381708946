import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { Account } from '@/client/accounts';
import { CourseFormState, EfrontCourse, MaterialType } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { CourseForm } from '@/components/courses/forms';
import { useSaveCourse } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { FlexContainer } from '@/ui/styled-ui';
import {
  generateCourseTranslationsInitialValues,
  parseModifiedCourseFiles,
  parseModifiedCourseTranslations,
  queryStateConverter,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import { MenuItem } from 'primereact/menuitem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getBreadcrumbs = (
  account: Account,
  t: TranslationFunctionType,
): MenuItem[] => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('courses'),
    url: RedirectPaths[RedirectPathsEnum.COURSES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('course.new'),
    url: RedirectPaths[RedirectPathsEnum.COURSES_CREATE](),
    template: AppBreadCrumbTemplate,
  },
];

export const CreateCoursePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const account = useAppSelector(selectCurrentAccount);

  const [formInitialValues, setFormInitialValues] = useState<CourseFormState>({
    courseTranslations: generateCourseTranslationsInitialValues(),
    courseLanguages: [LanguagesEnum.EN],
    courseThumbnail: '',
    courseFiles: [
      {
        [LanguagesEnum.EN]: {
          file: null,
        },
      },
    ],
    courseCreatedAt: undefined,
    courseLastUpdatedAt: undefined,
    type: MaterialType.COURSE,
    trial: false,
    isStandardCatalogue: false,
    releaseDate: null,
    customContent: false,
    customAccount: null,
    selfHosted: true,
    eFrontCourses: [],
    hubspotProperty: undefined,
    hubspotDateProperty: undefined,
    priority: null,
    label: undefined,
  });

  const createCourse = useSaveCourse();

  const handleSubmit = async (data: FormikValues) => {
    try {
      if (!account) return;

      const response = await createCourse.create({
        courseTranslations: parseModifiedCourseTranslations(
          data.courseTranslations,
        ),

        courseLanguages: data.courseLanguages,
        courseThumbnail: data.courseThumbnail ? data.courseThumbnail : null,
        courseFiles: parseModifiedCourseFiles(data.courseFiles, {
          selfHosted: data.selfHosted,
          courseLanguages: data.courseLanguages,
        }),
        courseCreatedAt: data.courseCreatedAt,
        courseLastUpdatedAt: data.courseLastUpdatedAt,
        type: data.type,
        trial: data.trial,
        isStandardCatalogue: data.isStandardCatalogue,
        releaseDate: data.releaseDate,
        account: account.isSystem
          ? data.customContent && !!data.customAccount
            ? data.customAccount
            : null
          : account.id,
        eFrontCourseIds: data.eFrontCourses?.map(
          (course: EfrontCourse) => course.id,
        ),
        hubspotProperty: data.hubspotProperty,
        hubspotDateProperty: data.hubspotDateProperty,
        priority: data?.priority,
        label: data?.label ? data.label : null,
      });

      toast?.success(t('toast.success'), t('course.created'));

      navigate(RedirectPaths[RedirectPathsEnum.COURSES_CONTENT](response.id));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      <AppBreadCrumb model={getBreadcrumbs(account as Account, t)} />
      <FlexContainer justify="flex-start">
        <CourseForm
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
          state={queryStateConverter(createCourse)}
          isCreate
          selfHosted
        />
      </FlexContainer>
    </>
  );
};
