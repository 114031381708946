import { LoadingStateType } from '@/common/constants';
import {
  availableLanguagesDropdown,
  courseOnlyLanguages,
} from '@/common/constants/languages';
import { FormikDropdown } from '@/components/form';
import { FormikInput } from '@/components/form/FormikInput';
import { updateProfileSettingsSchema } from '@/components/me/forms/validaitons';
import { ProfileSettings } from '@/pages/me';
import { AppButton } from '@/ui/buttons';
import { FormContainer } from '@/ui/styled-ui';
import {
  isMobile,
  preventDropdownBrowserTranslate,
} from '@/utils/helpers/ui.helper';
import { Field, Form, Formik } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ProfileSettingsFormProps = {
  initialValues: ProfileSettings;
  state?: LoadingStateType;
  onSubmit: (data: ProfileSettings) => void;
  isCreate?: boolean;
  disabled?: boolean;
  isSSOEnabled?: boolean;
};

export const ProfileSettingsForm: React.FC<ProfileSettingsFormProps> = ({
  initialValues,
  state,
  onSubmit,
  disabled,
  isSSOEnabled,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={updateProfileSettingsSchema(t)}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue, setFieldTouched }) => (
        <FormContainer width={isMobile() ? 100 : 50}>
          <h1 className="mt-4 mb-6">{t('generic.profile.settings')}</h1>
          <Form className="w-full" autoComplete="off">
            <div className="field w-full mb-4">
              <Field
                id="first-name"
                label={t('generic.firstName')}
                name="firstName"
                className="w-full"
                component={FormikInput}
                placeholder={t('generic.firstName')}
                required
                disabled={disabled}
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="last-name"
                label={t('generic.lastName')}
                name="lastName"
                className="w-full"
                component={FormikInput}
                placeholder={t('generic.lastName')}
                required
                disabled={disabled}
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="username"
                label={t('generic.username')}
                name="username"
                className="w-full"
                component={FormikInput}
                placeholder={t('generic.username')}
                required
                disabled
              />
            </div>
            <div className="field w-full mb-4">
              <Field
                id="email"
                label={t('generic.email')}
                name="email"
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('email', e.target.value.replace(/ /g, ''))
                }
                component={FormikInput}
                placeholder={t('generic.email')}
                required
                disabled
              />
            </div>
            {!isSSOEnabled && (
              <>
                <div className="field w-full mb-4">
                  <Field
                    id="password"
                    label={t('generic.password')}
                    name="password"
                    type="password"
                    className="w-full"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(
                        'password',
                        e.target.value.replace(/ /g, '') || undefined,
                      )
                    }
                    component={FormikInput}
                  />
                </div>
                <div className="field w-full mb-4">
                  <Field
                    id="repeatPassword"
                    label={t('profile.settings.confirmPassword')}
                    name="repeatPassword"
                    type="password"
                    className="w-full"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(
                        'repeatPassword',
                        e.target.value.replace(/ /g, ''),
                      )
                    }
                    component={FormikInput}
                    disabled={!values.password}
                  />
                </div>
              </>
            )}
            <div className="field w-full mb-4">
              <Field
                id="language"
                label={t('generic.lang')}
                name="language"
                filter
                onChange={(e: DropdownChangeEvent) =>
                  setFieldValue('language', e.value)
                }
                onBlur={() => setFieldTouched('language', true)}
                className="w-full"
                component={FormikDropdown}
                placeholder={t('generic.select')}
                options={availableLanguagesDropdown.filter(
                  ({ value }) => !courseOnlyLanguages.includes(value),
                )}
                required
                disabled={disabled}
                {...preventDropdownBrowserTranslate}
              />
            </div>

            <AppButton
              isSubmit
              severity="secondary"
              label={t('button.save')}
              className="w-4 mt-4 mb-5"
              state={state}
              isDisabled={!!Object.keys(errors).length || disabled}
            />
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};
