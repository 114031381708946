import { File } from '@/client/files';
import { isMobile } from '@/utils/helpers/ui.helper';
import { t } from 'i18next';
import 'pdfjs-dist/build/pdf.worker.min.mjs';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import styled from 'styled-components';
import { AppButton } from '../buttons';
import { FlexContainer } from '../styled-ui';

const StyledFlexContainer = styled(FlexContainer)`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    display: block;
  }

  .resizer {
    position: absolute;
    height: 100%;
  }

  .invisible {
    visibility: hidden;
  }
`;

type ScormPDFVieweProps = {
  file: File | null;
  hideNextButton?: boolean;
  onComplete: () => void;
};

const ScormPDFViewer: React.FC<ScormPDFVieweProps> = ({
  file,
  hideNextButton,
  onComplete,
}) => {
  const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setTotalPages(numPages);
    setIsDocumentLoaded(true);
  };

  useEffect(() => {
    document
      .querySelector('.content.without-sidebar')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pageNumber]);

  return (
    <>
      {/* PDF Document */}
      <StyledFlexContainer
        direction="row"
        justify="center"
        className="relative m-auto"
      >
        <div className="resizer" />
        <FlexContainer justify="center" width="auto">
          <AppButton
            severity="info"
            isDisabled={pageNumber === 1 || !isDocumentLoaded}
            size="xs"
            icon={'pi pi-chevron-left'}
            aria-label={t('generic.previousPage')}
            className={`mr-3 ${
              pageNumber === 1 || !isDocumentLoaded ? 'invisible' : ''
            } ${isMobile() ? 'mobile-left' : ''}`}
            onClick={() => setPageNumber(pageNumber - 1)}
          />
        </FlexContainer>
        <FlexContainer justify="center" width="auto">
          <Document
            file={file && file.signedUrl}
            loading={t('generic.loading')}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(e: Error) => console.dir(e)}
            onSourceError={(e: Error) => console.dir(e)}
          >
            <Page
              pageNumber={pageNumber}
              loading={t('generic.loading')}
              width={
                isMobile()
                  ? (document?.querySelector('.mobile')?.clientWidth || 0) - 100
                  : document?.querySelector('.resizer')?.clientWidth
              }
              onLoadError={(e: Error) => console.dir(e)}
              onRenderError={(e: Error) => console.dir(e)}
            />
          </Document>
        </FlexContainer>
        <FlexContainer justify="center" width="auto">
          <AppButton
            severity="info"
            isDisabled={!isDocumentLoaded}
            size="xs"
            icon={'pi pi-chevron-right'}
            aria-label={t('generic.nextPage')}
            className={`ml-3 ${hideNextButton ? 'invisible' : ''} ${
              isMobile() ? 'mobile-right' : ''
            }`}
            onClick={() => {
              if (pageNumber < totalPages) {
                setPageNumber(pageNumber + 1);
              } else {
                onComplete();
              }
            }}
          />
        </FlexContainer>
      </StyledFlexContainer>
    </>
  );
};

export default ScormPDFViewer;
