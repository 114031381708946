import { LanguagesEnum } from '@/api/enums';
import { MaterialType } from '@/client/courses';
import { TranslationFunctionType } from '@/common/types';
import { generateCourseTranslationsValidationSchema } from '@/utils/helpers';
import {
  AnyObject,
  array,
  boolean,
  date,
  number,
  object,
  string,
  TestContext,
} from 'yup';

export const createCourseSchema = (t: TranslationFunctionType) =>
  object({
    courseTranslations: generateCourseTranslationsValidationSchema(t),
    courseLanguages: array().when(['releaseDate'], ([releaseDate], schema) =>
      releaseDate === null
        ? schema.min(1, t('error.requiredField'))
        : schema.notRequired(),
    ),
    courseThumbnail: string().when(['releaseDate'], ([releaseDate], schema) =>
      releaseDate !== null
        ? schema.required(t('error.requiredField'))
        : schema.notRequired(),
    ),
    courseCreatedAt: date(),
    courseLastUpdatedAt: date(),
    customContent: boolean(),
    type: string(),
    categories: array().when(['type'], ([type], schema) =>
      type === MaterialType.POSTER
        ? schema.required(t('error.requiredField'))
        : schema.notRequired(),
    ),
    trial: boolean(),
    releaseDate: date().nullable(),
    priority: number().nullable(),
    eFrontCourses: array(),
    customAccount: string().when(['customContent'], ([customContent], schema) =>
      customContent === true
        ? schema.required()
        : schema.notRequired().nullable(),
    ),
    hubspotProperty: string().nullable(),
    hubspotDateProperty: string().nullable(),
    // Validate that each language have uploaded file
    courseFiles: array().test(
      'courseFilesRequired',
      t('error.requiredFile'),
      (schema, context: TestContext<AnyObject>) => {
        const value = context.parent;

        if (!value?.selfHosted) {
          return true;
        }

        const courseLanguages =
          !value.id || value.selfHosted
            ? value.courseLanguages
            : [LanguagesEnum.EN];

        for (const language of courseLanguages) {
          for (const file of value.courseFiles) {
            const matchingFiles = Object.keys(file).filter(
              (key: string) => key === language && file[key]?.file,
            );

            if (!matchingFiles?.length) {
              throw context.createError({
                path: 'courseFiles',
                message: t('error.requiredFile'),
              });
            }
          }
        }

        return true;
      },
    ),
  });

export const forceCompleteCourseSchema = (t: TranslationFunctionType) =>
  object({
    score: number()
      .min(1, t('error.OneToOneHundredNumber'))
      .max(100, t('error.maxScore'))
      .required('error.requiredField'),
  });
