import { CampaignFormValues } from '@/client/campaigns';
import { Subjects } from '@/client/users';
import {
  availableLanguagesForCatalogue,
  courseOnlyLanguages,
} from '@/common/constants/languages';
import {
  FormikDropdown,
  FormikInput,
  FormikRichTextArea,
  FormikWrappedSwitch,
} from '@/components/form';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { AppButton } from '@/ui/buttons';
import { CheckboxesContainer } from '@/ui/checkboxes-container';
import { FlexContainer } from '@/ui/styled-ui';
import {
  getPhishingSignOptions,
  getPhishingTypeOptions,
} from '@/utils/helpers';
import {
  difficultyOptions,
  preventDropdownBrowserTranslate,
  senderOptions,
} from '@/utils/helpers/ui.helper';
import { Field, FormikErrors } from 'formik';
import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsersScore } from '../UsersScore';

type InfoStepProps = {
  values: CampaignFormValues;
  errors: FormikErrors<CampaignFormValues>;
  valuesSetter: any;
};

export const InfoStep: React.FC<InfoStepProps> = ({
  values,
  errors,
  valuesSetter,
}) => {
  const { t } = useTranslation();
  const [canEditName, setCanEditName] = useState(false);
  const { canUseFeature } = useFeatureFlag();

  return (
    <>
      <h2>{t('campaign.template.info')}</h2>
      <FlexContainer gap={24} className="mb-4 h-full" align="stretch">
        <div className="flex-1">
          <FlexContainer justify="flex-start" align="flex-end" className="mb-4">
            <div className="field w-full m-0 relative">
              <Field
                id="name"
                name="name"
                label={t('generic.name')}
                value={values.name}
                className="w-full"
                component={FormikInput}
                disabled={!canEditName}
                data-testid="campaign-form-info-step-name"
              />
            </div>
            <AppButton
              icon="pi pi-pencil"
              type="text"
              onClick={() => setCanEditName(true)}
              isDisabled={canEditName}
              data-testid="campaign-form-info-step-enable-edit-name"
            />
          </FlexContainer>
          <FlexContainer gap={24} align="flex-start">
            <div className="field w-full mb-4 flex-1">
              <Field
                id="language"
                label={t('generic.lang')}
                name="language"
                required
                filter
                className="w-full"
                component={FormikDropdown}
                placeholder={t('generic.select')}
                options={availableLanguagesForCatalogue.filter(
                  ({ value }) => !courseOnlyLanguages.includes(value),
                )}
                {...preventDropdownBrowserTranslate}
              />
            </div>
            <div className="field w-full mb-4 flex-1">
              <Field
                id="difficulty"
                label={t('generic.difficulty')}
                name="difficulty"
                required
                className="w-full"
                component={FormikDropdown}
                placeholder={t('generic.select')}
                options={difficultyOptions}
              />
            </div>
          </FlexContainer>
          <div className="field w-full mb-4">
            <Field
              id="senderProfileType"
              label={t('generic.sender')}
              name="senderProfileType"
              required
              className="w-full"
              component={FormikDropdown}
              placeholder={t('generic.select')}
              options={senderOptions(t)}
            />
          </div>
          <div className="field w-full mb-4">
            <Field
              id="phishingSign"
              label={t('campaign.template.phishingSign')}
              name="phishingSign"
              className="w-full"
              component={FormikDropdown}
              placeholder={t('generic.select')}
              options={getPhishingSignOptions(t)}
            />
          </div>
          <div className="field w-full mb-4">
            <Field
              id="phishingType"
              label={t('campaign.template.phishingType')}
              name="phishingType"
              className="w-full"
              component={FormikDropdown}
              placeholder={t('generic.select')}
              options={getPhishingTypeOptions(t)}
            />
          </div>

          {canUseFeature(Subjects.PHISHING_BENCHMARK) &&
            !!values?.benchmarkSummary && (
              <>
                <label htmlFor="usersScore">{t('campaigns.userScore')} </label>
                <FlexContainer gap={24} className="mb-4 mt-2" align="stretch">
                  <UsersScore benchmark={values.benchmarkSummary} />
                </FlexContainer>
              </>
            )}

          <CheckboxesContainer
            className="toggle-container"
            id="isTemplate-active"
          >
            <Field
              disabled={
                !!Object.keys(errors).length && values?.isPublished === false
              }
              inputId="isPublished"
              name="isPublished"
              label={t('campaign.template.isTemplate')}
              component={FormikWrappedSwitch}
            />
          </CheckboxesContainer>
          {!!Object.keys(errors).length && (
            <Tooltip
              position="mouse"
              target="#isTemplate-active"
              content={`${t('campaign.template.selectAll')}\n${Object.values(
                errors,
              ).join(', \n')}`}
            />
          )}
        </div>
        <div className="flex-1 h-auto field mb-0">
          <Field
            id="rich-text-editor"
            label={t('generic.description')}
            name="description"
            className="w-full"
            placeholder={t('generic.description')}
            component={FormikRichTextArea}
          />
        </div>
      </FlexContainer>
    </>
  );
};
