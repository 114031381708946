import { languageDisplayCode } from '@/api/enums';
import {
  CampaignStatusesEnum,
  CampaignStatusMessages,
} from '@/client/campaigns';
import { User } from '@/client/users';
import { TranslationFunctionType } from '@/common/types';
import { useToast } from '@/hooks/useToast';
import { getEmailTypesLabels } from '@/utils/helpers';
import { t } from 'i18next';

export const displayCreatedBy = (
  t: TranslationFunctionType,
  createdBy?: User | null,
) => {
  if (!createdBy) return t('generic.unkown');

  return `${createdBy.firstName} ${createdBy.lastName}`;
};

export const displaySenderProfile = (
  t: TranslationFunctionType,
  senderProfileName?: string,
) => {
  if (!senderProfileName) return t('generic.unkown');

  return t('campaign.template.createdByName', { name: senderProfileName });
};

export const displaySystemEmailTemplateName = (
  name: string,
  t: TranslationFunctionType,
) => {
  const [emailType, language] = name.split(' ');

  return `${getEmailTypesLabels(t)[emailType]}, ${
    languageDisplayCode[language]
  }`;
};

export const displayCampaignStatus = (
  t: TranslationFunctionType,
  status?: CampaignStatusesEnum,
) => {
  if (!status) return t('generic.unkown');

  return t(CampaignStatusMessages[status]);
};

export const decodeHTML = (str: string): string => {
  let decoded: string = str;

  const htmlEntities = [
    { regex: /&amp;/g, entity: '&' },
    { regex: /&gt;/g, entity: '>' },
    { regex: /&lt;/g, entity: '<' },
    { regex: /&quot;/g, entity: '"' },
  ];

  for (const v in htmlEntities) {
    decoded = decoded.replace(htmlEntities[v].regex, htmlEntities[v].entity);
  }

  return decoded;
};

export const handleHandlebarsError = (
  e: any,
  toast: ReturnType<typeof useToast>,
) =>
  toast?.error(
    t('error'),
    t('error.invalidTemplate', { message: decodeHTML(e.message) }),
  );
