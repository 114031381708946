import { languageDisplayLabel } from '@/api/enums';
import { equal, handleAxiosError, notEqual } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { Account } from '@/client/accounts';
import {
  EmailType,
  SystemEmailsTemplatesFormValues,
  SystemEmailTemplate,
} from '@/client/system-emails';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TemplateSystemEmailsTemplatesBuilderLegend,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import { BeePluginEmailContainer } from '@/components/bee-plugin';
import { StateProvider } from '@/components/state-provider';
import { ClipboardContainer } from '@/components/templates';
import {
  useSystemEmailTemplate,
  useUpdateSystemEmailTemplate,
} from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import { default as plainTemplate } from '@/pages/email-templates/templates/plain-template.json';
import { selectCurrentAccount } from '@/store/features/account';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { AbsoluteLoader, FlexContainer } from '@/ui/styled-ui';
import { getEmailTypesLabels } from '@/utils/helpers';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { Steps } from 'primereact/steps';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SystemEmailTemplateInformationForm } from '../components';
import { isNotValidHandlebarsEmailTemplateCheck } from '../helpers';

const StyledFormWrapper = styled.div`
  padding: var(--default-padding);
`;

const pathItems = (
  account: Account,
  systemEmail: SystemEmailTemplate,
  t: TranslationFunctionType,
) => [
  {
    label: account?.name,
    url: !account?.isSystem
      ? RedirectPaths[RedirectPathsEnum.ACCOUNT](account?.id)
      : RedirectPaths[RedirectPathsEnum.EDIT_ACCOUNT](account?.id),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('generic.system.emails'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('templates'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES](),
    template: AppBreadCrumbTemplate,
  },
  {
    label: t('system.email.templates.edit'),
    url: RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES_EDIT](
      systemEmail?.id,
    ),
    template: AppBreadCrumbTemplate,
  },
];

const steps = (t: TranslationFunctionType) => [
  {
    label: t('templates.info'),
  },
  {
    label: t('templates.structure'),
  },
];

const initialValues = (systemEmailTemplate: SystemEmailTemplate) => ({
  type: systemEmailTemplate.type,
  language: systemEmailTemplate.language,
  subject: systemEmailTemplate.subject,
  text: systemEmailTemplate.text,
  active: systemEmailTemplate.active,
});

export const SystemEmailsTemplatesUpdatePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { systemEmailTemplate, isLoading } = useSystemEmailTemplate({
    emailId: id,
  });

  return (
    <StateProvider
      state={systemEmailTemplate}
      isLoading={isLoading}
      loadingMessage={t('system.email.template.data.loading')}
      errorMessage={t('system.email.template.data.error')}
    >
      <SystemEmailsTemplatesUpdateWithState
        systemEmailTemplate={systemEmailTemplate as SystemEmailTemplate}
      />
    </StateProvider>
  );
};

const SystemEmailsTemplatesUpdateWithState: React.FC<{
  systemEmailTemplate: SystemEmailTemplate;
}> = ({ systemEmailTemplate }) => {
  const { t } = useTranslation();
  const account = useAppSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const toast = useToast();
  const { setDialogData } = useContext(DialogContext);

  const [activeStep, setActiveStep] = useState(0);
  const [blockNextStep, setBlockNextStep] = useState(true);
  const [isTemplateChanged, setIsTemplateChanged] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const updateSystemEmailTemplate = useUpdateSystemEmailTemplate();

  // this is used to prevent the form from reinitializing on every change made
  const [initialFormValues, setInitialFormValues] =
    useState<SystemEmailsTemplatesFormValues>(
      initialValues(systemEmailTemplate),
    );
  const [formValues, setFormValues] = useState<SystemEmailsTemplatesFormValues>(
    initialValues(systemEmailTemplate),
  );

  // Set initial template for Bee
  const [initialTemplate, setInitialTemplate] = useState(
    systemEmailTemplate.editor ?? (plainTemplate as object),
  );

  // Unblock step 2 if the form is valid
  const toggleStepBlocked = (isValid: boolean) => {
    setBlockNextStep(!isValid);
  };

  const showExitPrompt = () => {
    const formValuesToCompare = initialValues(systemEmailTemplate);
    return !isTemplateChanged && isEqual(formValuesToCompare, formValues);
  };

  // Track changes to the template
  const handleChangeTemplate = (page: any) => {
    setInitialTemplate(page);
    setIsTemplateChanged(true);
  };

  const handleNextStep = async (
    type: EmailType,
    language: string,
    step: number,
  ) => {
    try {
      const systemEmailsTemplates =
        await client.systemEmailsTemplatesService.getSystemEmailsTemplates({
          filters: [
            equal('type', type),
            equal('language', language),
            notEqual('id', systemEmailTemplate.id),
            equal('account', account?.isSystem ? null : account?.id),
          ],
        });

      if (systemEmailsTemplates.count && systemEmailTemplate) {
        toast?.error(
          t('system.email.template.combination'),
          t('system.email.template.unavailable.combination'),
        );

        return;
      }

      setActiveStep(step);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const triggerNextStep = async () =>
    await handleNextStep(formValues.type as EmailType, formValues.language, 1);

  const handleSaveEmailTemplate = async (page: object, html: string) => {
    try {
      if (!account || !systemEmailTemplate) {
        return;
      }

      const isNotValid = isNotValidHandlebarsEmailTemplateCheck(
        html,
        toast,
        formValues.subject,
        formValues.text,
        true,
      );

      if (isNotValid) {
        setShowLoader(false);
        return;
      }

      // TODO: Hit the save bee plugin here, and get the page and html from it
      const params: SystemEmailsTemplatesFormValues & {
        editor: object;
        html: string;
      } = {
        type: formValues.type,
        language: formValues.language,
        subject: formValues.subject,
        text: formValues.text,
        editor: page,
        html: html,
        // TODO: Uncomment when we integrate our own Save button
        // editor: initialTemplate || plainTemplate,
        // html: '<!DOCTYPE html></html>', // Find a way to get the html form BEE
        active: formValues?.active,
        account: account?.isSystem ? null : (account?.id as string),
      };

      setShowLoader(true);
      await updateSystemEmailTemplate.update({
        emailId: systemEmailTemplate.id,
        ...params,
      });
      setShowLoader(false);
      toast?.success(t('toast.success'), t('templates.email.updated'));

      navigate(RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES]());
    } catch (e) {
      setShowLoader(false);
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const handleExit = () => {
    const navigateUrl =
      RedirectPaths[RedirectPathsEnum.SYSTEM_EMAILS_TEMPLATES]();

    if (showExitPrompt()) {
      navigate(navigateUrl);

      return;
    }

    setDialogData({
      type: 'confirmation',
      show: true,
      header: t('dialog.unsavedChanges'),
      message: t('dialog.unsavedChanges.confirm'),
      onAccept: () => navigate(navigateUrl),
    });
  };

  return (
    <FlexContainer
      height="100%"
      direction="column"
      align="flex-start"
      justify="flex-start"
    >
      {showLoader && <AbsoluteLoader message={t('templates.saving')} />}
      <div id="template-header-container">
        <AppBreadCrumb
          model={pathItems(
            account as Account,
            systemEmailTemplate as SystemEmailTemplate,
            t,
          )}
        />
        <h1>
          {t('system.email.templates.heading', {
            emailType: getEmailTypesLabels(t)[systemEmailTemplate?.type],
            emailLanguage: languageDisplayLabel[systemEmailTemplate?.language],
          })}
        </h1>

        <Steps
          model={steps(t)}
          activeIndex={activeStep}
          onSelect={async (e) => {
            // Check if the combination of type and language is available only when switching from step 0 to 1
            if (e.index === 1) {
              await triggerNextStep();
              return;
            }

            setInitialFormValues(formValues);
            setActiveStep(e.index);
          }}
          readOnly={blockNextStep}
        />
        <ClipboardContainer
          clipboardItems={TemplateSystemEmailsTemplatesBuilderLegend(
            t,
            formValues?.type as EmailType,
          )}
        />
      </div>
      <div id="template-content-container">
        {activeStep === 0 && (
          <StyledFormWrapper>
            <SystemEmailTemplateInformationForm
              initialValues={initialFormValues}
              onInputChange={setFormValues}
              onValidateForm={toggleStepBlocked}
            />
          </StyledFormWrapper>
        )}
        {activeStep === 1 && (
          <BeePluginEmailContainer
            template={initialTemplate || plainTemplate}
            handleChange={handleChangeTemplate}
            handleSave={handleSaveEmailTemplate}
          />
        )}
      </div>
      <FlexContainer id="template-footer-container" justify="space-between">
        <div>
          <AppButton
            type="outlined"
            severity="secondary"
            label={t('button.exit')}
            className="mr-3"
            onClick={handleExit}
          />
          {/* TODO: Uncomment when we find a way to get the html onChange */}
          {/* <AppButton
            severity="secondary"
            label={t('button.save)}
            onClick={handleSaveEmailTemplate}
            isDisabled={blockNextStep}
          /> */}
        </div>

        <div>
          {activeStep > 0 && (
            <AppButton
              severity="secondary"
              label={t('button.back')}
              icon="pi pi-arrow-left"
              iconPos="left"
              onClick={() => {
                setInitialFormValues(formValues);
                setActiveStep(0);
              }}
              className="mr-3"
            />
          )}
          {activeStep < 1 && (
            <AppButton
              severity="secondary"
              label={t('button.next')}
              icon="pi pi-arrow-right"
              iconPos="right"
              onClick={triggerNextStep}
              isDisabled={blockNextStep || activeStep === 1}
            />
          )}
        </div>
      </FlexContainer>
    </FlexContainer>
  );
};
