import { LanguagesEnum } from '@/api/enums';
import {
  Campaign,
  CampaignEmailingEnum,
  CampaignFormValues,
  CampaignPhishingSignEnum,
  CampaignPhishingTypeEnum,
  CampaignStatusesEnum,
  CampaignStatusMessages,
  CampaignTemplate,
  CampaignUserResultMessages,
  CampaignUserResultStatusesEnum,
  SaveCampaignRequest,
  SenderProfileType,
} from '@/client/campaigns';
import { CourseEntityEnrollEnum } from '@/client/courses';
import { User } from '@/client/users';
import { TargetEntity, TranslationFunctionType } from '@/common/types';
import {
  checkIfDateIsPastDate,
  checkIfDatesAreEqual,
  checkIfNowOrAfter,
} from '@/utils/helpers/dates.helper';
import { targetGroupInitialValues } from '@/utils/helpers/target-group.helper';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { getTargetEntityIdsByType } from '../targets';

const setSendByDate = (
  values?: CampaignFormValues,
  transformDates?: boolean,
) => {
  if (!transformDates)
    return values?.periodType === CampaignEmailingEnum.BLAST
      ? null
      : values?.sendByDate;

  if (
    values?.periodType === CampaignEmailingEnum.BLAST ||
    !checkIfNowOrAfter(values?.launchDate) ||
    checkIfDatesAreEqual(values?.launchDate, values?.sendByDate)
  )
    return null;

  return !checkIfNowOrAfter(values?.sendByDate)
    ? new Date()
    : values?.sendByDate;
};

const setLaunchDate = (
  values?: CampaignFormValues,
  transformDates?: boolean,
) => {
  if (!transformDates) return values?.launchDate;

  return values?.launchDate &&
    checkIfDateIsPastDate(new Date(), values.launchDate)
    ? new Date()
    : values?.launchDate;
};

export const isWithinHourCheck = (time: Date | undefined): boolean => {
  if (!time) return false;
  const launchTime = moment(time);
  const currentDate = moment();

  const diffInHours = launchTime.diff(currentDate, 'hours');

  return diffInHours < 1 ? true : false;
};

export const prepareRequestData = (
  accountId: string,
  values?: CampaignFormValues,
  transformDates?: boolean,
): SaveCampaignRequest => {
  const launchDate = setLaunchDate(values, transformDates);
  const sendByDate = setSendByDate(values, transformDates);

  return {
    encodeEmailTemplateImages: values?.encodeEmailTemplateImages,
    name: values?.name,
    emailTemplate: values?.emailTemplate,
    landingPage: values?.landingPage,
    launchDate,
    sendByDate,
    sendingProfile: values?.sendingProfile?.id,
    url: undefined,
    account: accountId,
    users: values?.targetGroup?.usersList.map((user) => user.id),
    targets: {
      branches: getTargetEntityIdsByType(
        values?.targets ?? [],
        CourseEntityEnrollEnum.BRANCHES,
      ),
      groups: getTargetEntityIdsByType(
        values?.targets ?? [],
        CourseEntityEnrollEnum.GROUPS,
      ),
    },
    excludes: {
      branches: getTargetEntityIdsByType(
        values?.excludes ?? [],
        CourseEntityEnrollEnum.BRANCHES,
      ),
      groups: getTargetEntityIdsByType(
        values?.excludes ?? [],
        CourseEntityEnrollEnum.GROUPS,
      ),
      users: getTargetEntityIdsByType(
        values?.excludes ?? [],
        CourseEntityEnrollEnum.USERS,
      ),
    },
    isPublished: values?.isPublished,
    useDynamicTarget: values?.useDynamicTarget,
    language: values?.language,
    difficulty: values?.difficulty,
    senderProfileType: values?.senderProfileType,
    clickedLinksPercentage: values?.clickedLinksPercentage,
    submittedDataPercentage: values?.submittedDataPercentage,
    description: values?.description,
    phishingType: values?.phishingType,
    phishingSign: values?.phishingSign,
    isAutoPhishing: values?.isAutoPhishing,
  };
};

export const getCampaignSubmitValueByStep = (
  step: number,
  data: SaveCampaignRequest,
  isNewPhishingSelectorEnabled: boolean,
) => {
  const modifiedData: { [key: number]: any } = {
    0: {
      encodeEmailTemplateImages: data?.encodeEmailTemplateImages,
      emailTemplate: data?.emailTemplate,
      name: data?.name,
    },
    1: {
      landingPage: data?.landingPage,
    },
    2: {
      sendingProfile: data?.sendingProfile,
    },
    3: {
      ...(isNewPhishingSelectorEnabled
        ? {
            targets: data.targets,
            excludes: data.excludes,
            useDynamicTarget: data.useDynamicTarget,
          }
        : { users: data?.users }),
    },
    4: {
      name: data?.name,
      launchDate: data?.launchDate,
      sendByDate: data?.sendByDate,
      ...(isWithinHourCheck(data?.launchDate) &&
      !!data?.useDynamicTarget &&
      isNewPhishingSelectorEnabled
        ? { useDynamicTarget: false }
        : {}),
      isAutoPhishing: data?.isAutoPhishing,
    },
  };

  return { ...modifiedData[step], account: data.account };
};

export const getCampaignTemplateSubmitValueByStep = (
  step: number,
  data: SaveCampaignRequest,
) => {
  const modifiedData: { [key: number]: any } = {
    0: {
      encodeEmailTemplateImages: data?.encodeEmailTemplateImages,
      emailTemplate: data?.emailTemplate,
      name: data?.name,
    },
    1: {
      landingPage: data?.landingPage,
    },
    2: {
      sendingProfile: data?.sendingProfile,
    },
    3: {
      name: data?.name,
      isPublished: data?.isPublished,
      language: data?.language,
      difficulty: data?.difficulty,
      senderProfileType: data?.senderProfileType,
      clickedLinksPercentage: data?.clickedLinksPercentage,
      submittedDataPercentage: data?.submittedDataPercentage,
      description: data?.description,
      phishingType: data?.phishingType,
      phishingSign: data?.phishingSign,
    },
  };

  return { ...modifiedData[step], account: data.account };
};

export const setCampaignInitialValues = (
  campaign: Campaign,
  users: User[],
  isNewPhishingSelectorEnabled: boolean,
  includedEntities: TargetEntity[],
  excludedEntities: TargetEntity[],
): CampaignFormValues => ({
  encodeEmailTemplateImages: campaign?.meta?.encodeEmailTemplateImages,
  emailTemplate: campaign?.emailTemplate?.id,
  landingPage: campaign?.landingPage?.id,
  ...(isNewPhishingSelectorEnabled
    ? { targets: includedEntities, excludes: excludedEntities }
    : {
        targetGroup: {
          ...targetGroupInitialValues,
          usersList: users,
        },
      }),

  sendingProfile: campaign?.sendingProfile,
  periodType: campaign?.sendByDate
    ? CampaignEmailingEnum.DRIP
    : CampaignEmailingEnum.BLAST,
  sendByDate: campaign?.sendByDate && new Date(campaign.sendByDate),
  launchDate: campaign?.launchDate && new Date(campaign.launchDate),
  name: campaign?.name,
  ...(isNewPhishingSelectorEnabled
    ? {
        useDynamicTarget:
          campaign.useDynamicTarget === null
            ? true
            : !!campaign?.useDynamicTarget,
      }
    : {}),
  meta: campaign?.meta,
  isAutoPhishing: campaign?.isAutoPhishing,
});

export const setCampaignTemplateInitialValues = (
  campaign: CampaignTemplate,
): CampaignFormValues => ({
  encodeEmailTemplateImages: campaign?.meta?.encodeEmailTemplateImages,
  emailTemplate: campaign?.emailTemplate?.id,
  landingPage: campaign?.landingPage?.id,
  sendingProfile: campaign?.sendingProfile,
  name: campaign?.name,
  language: campaign?.language ?? LanguagesEnum.EN,
  difficulty: campaign?.difficulty ?? undefined,
  senderProfileType: campaign?.senderProfileType ?? SenderProfileType.INTERNAL,
  clickedLinksPercentage: campaign?.clickedLinksPercentage ?? undefined,
  submittedDataPercentage: campaign?.submittedDataPercentage ?? undefined,
  description: campaign?.description ?? '',
  isPublished: campaign?.isPublished,
  phishingType: campaign?.meta?.phishingType,
  phishingSign: campaign?.meta?.phishingSign,
  meta: campaign?.meta,
  benchmarkSummary: campaign?.benchmarkSummary,
});

export const getCampaignStatusOptions = (t: TranslationFunctionType) => [
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.ACTIVE]),
    value: CampaignStatusesEnum.ACTIVE,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.DRAFT]),
    value: CampaignStatusesEnum.DRAFT,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.SCHEDULED]),
    value: CampaignStatusesEnum.SCHEDULED,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.COMPLETED]),
    value: CampaignStatusesEnum.COMPLETED,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.PENDING_LAUNCH]),
    value: CampaignStatusesEnum.PENDING_LAUNCH,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.PENDING_COMPLETION]),
    value: CampaignStatusesEnum.PENDING_COMPLETION,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.FAILED_LAUNCH]),
    value: CampaignStatusesEnum.FAILED_LAUNCH,
  },
  {
    label: t(CampaignStatusMessages[CampaignStatusesEnum.FAILED_COMPLETION]),
    value: CampaignStatusesEnum.FAILED_COMPLETION,
  },
];

export const getCampaignUserResultStatuses = (
  t: TranslationFunctionType,
  showEmailOpened?: boolean,
) =>
  [
    {
      label: t(
        CampaignUserResultMessages[
          CampaignUserResultStatusesEnum.CAMPAIGN_CREATED
        ],
      ),
      value: [CampaignUserResultStatusesEnum.CAMPAIGN_CREATED],
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.EMAIL_SENT],
      ),
      value: showEmailOpened
        ? CampaignUserResultStatusesEnum.EMAIL_SENT
        : [
            CampaignUserResultStatusesEnum.EMAIL_SENT,
            CampaignUserResultStatusesEnum.EMAIL_OPENED,
          ],
    },
    showEmailOpened
      ? {
          label: t(
            CampaignUserResultMessages[
              CampaignUserResultStatusesEnum.EMAIL_OPENED
            ],
          ),
          value: CampaignUserResultStatusesEnum.EMAIL_OPENED,
        }
      : {},
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.CLICKED_LINK],
      ),
      value: CampaignUserResultStatusesEnum.CLICKED_LINK,
    },
    {
      label: t(
        CampaignUserResultMessages[
          CampaignUserResultStatusesEnum.SUBMITTED_DATA
        ],
      ),
      value: CampaignUserResultStatusesEnum.SUBMITTED_DATA,
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.ERROR],
      ),
      value: CampaignUserResultStatusesEnum.ERROR,
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.SCHEDULED],
      ),
      value: CampaignUserResultStatusesEnum.SCHEDULED,
    },
    {
      label: t(
        CampaignUserResultMessages[CampaignUserResultStatusesEnum.SENDING],
      ),
      value: CampaignUserResultStatusesEnum.SENDING,
    },
  ].filter((item) => !isEmpty(item));

export const getPhishingSignOptions = (t: TranslationFunctionType) =>
  Object.values(CampaignPhishingSignEnum).map((sign) => ({
    label: t(`campaign.template.${sign}`),
    value: sign,
  }));

export const getPhishingTypeOptions = (t: TranslationFunctionType) =>
  Object.values(CampaignPhishingTypeEnum).map((type) => ({
    label: t(`campaign.type.${type}`),
    value: type,
  }));
