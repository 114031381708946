import { equal, isIn } from '@/api/helpers';
import { Account } from '@/client/accounts';
import {
  Course,
  CourseAvailabilityFormValues,
  CourseAvailabilityTypeEnum,
} from '@/client/courses';
import { AccountsMultiselectInput } from '@/components/form/selectors';
import { AppButton } from '@/ui/buttons';
import { FlexContainer } from '@/ui/styled-ui';
import { Form, Formik } from 'formik';
import { Dialog, DialogProps } from 'primereact/dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { courseAvailabilitySchema } from '../validations';

const StyledDialog = styled(Dialog)`
  width: 500px;
`;

type CourseAvailabilityModalProps = {
  onSubmit: (data: CourseAvailabilityFormValues) => void;
  extended?: boolean;
  type: CourseAvailabilityTypeEnum;
  course: Course;
} & DialogProps;

export const CourseAvailabilityModal: React.FC<
  CourseAvailabilityModalProps
> = ({ onSubmit, onHide, type, course, visible }) => {
  const { t } = useTranslation();
  const [initialValues] = useState<CourseAvailabilityFormValues>({
    accounts: [],
  });

  const isMakeAvailable = type === CourseAvailabilityTypeEnum.MAKE_AVAILABLE;

  const handleAccountsSelect = (
    account: Account[],
    manualChange: boolean,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    setFieldValue('accounts', account);
  };

  return (
    <StyledDialog
      blockScroll
      visible={visible}
      header={
        <h1>
          {isMakeAvailable
            ? t('generic.accounts.available')
            : t('generic.accounts.unavailable')}
        </h1>
      }
      onHide={onHide}
      draggable={false}
      data-testid="make-course-available-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={courseAvailabilitySchema(t)}
      >
        {({ setFieldValue, values, errors }) => (
          <>
            <Form>
              <div className="field w-full mb-4">
                <label>{`${t('generic.selected')} (${
                  values.accounts?.length
                    ? values.accounts?.length
                    : t('generic.none')
                })`}</label>
                {isMakeAvailable ? (
                  <AccountsMultiselectInput
                    className="w-full"
                    onChange={(e, manualChange) =>
                      handleAccountsSelect(e, manualChange, setFieldValue)
                    }
                    selectedOptions={values.accounts}
                    getOnlyUnavailableAccounts
                    additionalFilters={[
                      equal('active', true),
                      ...(course?.account?.id
                        ? [equal('id', course?.account?.id)]
                        : []),
                    ]}
                    courseId={course?.id}
                  />
                ) : (
                  <AccountsMultiselectInput
                    className="w-full"
                    onChange={(e, manualChange) =>
                      handleAccountsSelect(e, manualChange, setFieldValue)
                    }
                    additionalFilters={[
                      isIn('availableCourses', [course?.id]),
                      equal('active', true),
                      ...(course?.account?.id
                        ? [equal('id', course?.account?.id)]
                        : []),
                    ]}
                    selectedOptions={values.accounts}
                  />
                )}
              </div>

              <FlexContainer justify="flex-end" className="mt-5">
                <AppButton
                  label={t('button.cancel')}
                  severity="secondary"
                  type="outlined"
                  onClick={() => {
                    onHide();
                  }}
                  className="mr-3"
                  data-testid="make-course-available-cancel-form"
                />
                <AppButton
                  label={
                    isMakeAvailable
                      ? t('generic.accounts.available')
                      : t('generic.accounts.unavailable')
                  }
                  severity="secondary"
                  isSubmit
                  data-testid="make-course-available-submit-form"
                  isDisabled={!!Object.keys(errors).length}
                />
              </FlexContainer>
            </Form>
          </>
        )}
      </Formik>
    </StyledDialog>
  );
};
