import { Skeleton } from 'primereact/skeleton';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledThumbnail = styled.div`
  flex-shrink: 0;
  width: 76px;
  height: 66px;
  overflow: hidden;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

type DatatableThumbnailProps = {
  url: string;
  alt?: string;
  className?: string;
};

const DatatableThumbnail: React.FC<DatatableThumbnailProps> = ({
  url,
  alt = '',
  ...rest
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <StyledThumbnail {...rest}>
      {!isImageLoaded && <Skeleton height="100%" />}
      <img src={url} alt={alt} onLoad={() => setIsImageLoaded(true)} />
    </StyledThumbnail>
  );
};

export default DatatableThumbnail;
