import { URL_REGEX } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { array, boolean, date, object, string } from 'yup';

export const securityCultureSchema = (
  t: TranslationFunctionType,
  useTypeForm: boolean,
) =>
  object().shape({
    targets: array().min(1, t('error.requiredField')),
    typeFormId: string(),
    url: useTypeForm
      ? string().nullable()
      : string()
          .matches(URL_REGEX || '' || null, t('error.invalidUrl'))
          .required(t('error.requiredField')),
  });

export const securityCultureUploadSurveySchema = (
  t: TranslationFunctionType,
  isDemo?: boolean,
) =>
  object().shape({
    published: boolean(),
    file: object().when(['published'], ([published], schema) =>
      published || isDemo
        ? schema.required(t('error.requiredFile'))
        : schema.notRequired(),
    ),
    targets: isDemo ? array() : array().min(1, t('error.requiredField')),
    date: isDemo ? date() : date().required(t('error.requiredField')),
  });
