import { Conditions } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import { Account } from '@/client/accounts';

import {
  Campaign,
  CampaignActionsEnum,
  CampaignStatusesEnum,
  CampaignStatusMessages,
  CampaignTemplate,
  CampaignType,
  ListCampaignsResponse,
  SendTestEmailFormValues,
} from '@/client/campaigns/types';

import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import { Actions, Subjects } from '@/client/users';
import {
  LoadingStatuses,
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import { TranslationFunctionType } from '@/common/types';
import {
  CampaignTemplatesModal,
  SendTestEmailModal,
} from '@/components/campaigns';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  FilterTypeEnum,
} from '@/components/tables/crud';
import { useNotifications } from '@/hooks/notifications.hook';
import {
  useCampaignAction,
  useCampaigns,
  useCampaignsSummary,
  useCopyCampaign,
  useDeleteCampaign,
  useSaveCampaign,
  useSendTestEmail,
  useUpdateCampaign,
} from '@/hooks/query';
import {
  useCampaignTemplates,
  useCopyCampaignTemplate,
  useDeleteCampaignTemplate,
  useSaveCampaignTemplate,
} from '@/hooks/query/campaign-templates.hook';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUserState } from '@/store/features/users';
import { AppButton, AppDropdownButton } from '@/ui/buttons';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { ImageCard } from '@/ui/image-card';
import { AppSelectPreviewType } from '@/ui/select-preview';
import { CardGridContainer, FlexContainer } from '@/ui/styled-ui';
import {
  displayCampaignStatus,
  displayCreatedBy,
  getCampaignStatusOptions,
  getPhishingSignOptions,
  getPhishingTypeOptions,
  PreviewType,
  queryStateConverter,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import { default as React, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { default as styled } from 'styled-components';
import { v4 } from 'uuid';
import { GridTable } from '../grid-table';
import { DataTableFilters, DataTableToolbar } from '../tables/crud';
import { ThumbnailsGenerationNotify } from '../thumbnails';

const StyledDiv = styled.div`
  width: 100%;
  padding-top: var(--default-padding);

  .p-datatable {
    th {
      &.email-sent-icon .p-column-title:before,
      &.users-icon .p-column-title:before,
      &.email-opened-icon .p-column-title:before,
      &.clicked-link-icon .p-column-title:before,
      &.submitted-data-icon .p-column-title:before,
      &.email-reported-icon .p-column-title:before {
        font-family: var(--icon-font-family);
        display: inline-block;
        color: var(--black-main);
        font-size: var(--medium-font-size);
        line-height: var(--medium-line-height);
        padding-right: 2px;
      }

      &.email-sent-icon .p-column-title:before {
        font-weight: 500;
        content: '\f0e0';
      }

      &.users-icon .p-column-title:before {
        font-weight: 500;
        content: '\f007';
      }

      &.email-opened-icon .p-column-title:before {
        font-weight: 500;
        content: '\f2b6';
      }

      &.clicked-link-icon .p-column-title:before {
        font-weight: 900;
        content: '\f245';
      }

      &.submitted-data-icon .p-column-title:before {
        font-weight: 900;
        content: '\f06a';
      }

      &.email-reported-icon .p-column-title:before {
        font-weight: 900;
        content: '\f0a1';
      }

      &.p-highlight .p-column-title:before {
        color: var(--red-main);
      }
    }
  }
`;

const StyledCardGridViewContainer = styled(CardGridContainer)`
  margin: 0;
  width: 100%;
  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledText = styled.div`
  color: var(--gray-darker);
  font-size: var(--xsmall-font-size);
  line-height: var(--xsmall-line-heigth);
  font-style: italic;
  min-width: max-content;
`;

const getStatus = (
  status: CampaignStatusesEnum,
  isDynamic: boolean,
  t: TranslationFunctionType,
) => {
  return (
    <>
      <div>{t(CampaignStatusMessages[status])}</div>
      {isDynamic && <StyledText>({t('campaign.dynamicTarget')})</StyledText>}
    </>
  );
};

export const CampaignList = () => {
  const { can, cannot } = usePermission();
  const { canUseFeature } = useFeatureFlag();
  const account = useAppSelector(selectCurrentAccount);
  const { user } = useAppSelector(selectCurrentUserState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { setDialogData } = useContext(DialogContext);
  const [selectedPreviewType, setSelectedPreviewType] = useState<PreviewType>(
    PreviewType.TABLE,
  );
  const isTable = selectedPreviewType === PreviewType.TABLE;

  const {
    skip,
    sort,
    take,
    apiFilters,
    onSort,
    onFilter,
    onPage,
    onGridPageChange,
    onGridRowChange,
  } = useTable();

  const [data, setData] = useState<ListCampaignsResponse>();

  const {
    isLoading: areCampaignsLoading,
    campaigns,
    refetch,
  } = useCampaigns({
    withTemplates: isTable ? 0 : 1,
    take,
    skip,
    enabled: !account?.isSystem,
    filters: [
      ...apiFilters,
      {
        field: 'account',
        condition: Conditions.EQUAL,
        value: (account as Account)?.id,
      },
    ],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  const {
    isLoading: areCampaignTemplatesLoading,
    campaignTemplates,
    refetch: refetchCampaignTemplates,
  } = useCampaignTemplates({
    withPreview: isTable ? 0 : 1,
    take,
    skip,
    enabled: account?.isSystem,
    filters: [...apiFilters],
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  useEffect(() => {
    if (account?.isSystem && !areCampaignTemplatesLoading) {
      setData(campaignTemplates);
    } else if (!account?.isSystem && !areCampaignsLoading) {
      setData(campaigns);
    }
  }, [campaigns, campaignTemplates]);

  const {
    lastMessage,
    setNotificationParam: setCampaignId,
    notificationParam: notificationCampaignId,
    isConnected,
  } = useNotifications(client.campaigns.campaignsNotifyUrl);

  const [copyState, setCopyState] = useState<LoadingStatuses>(
    LoadingStatuses.IDLE,
  );
  const [showCampaignTemplatesModal, setShowCampaignTemplatesModal] =
    useState(false);

  const [deletingCampaignId, setDeletingCampaignId] = useState<string | null>(
    null,
  );

  const [showSendTestEmailForACampaing, setShowSendTestEmailForACampaing] =
    useState<Campaign | null>(null);

  const deleteCampaign = useDeleteCampaign();
  const deleteCampaignTemplate = useDeleteCampaignTemplate();

  const {
    isLoading: isLoadingSummary,
    campaignsSummary,
    refetch: summaryRefetch,
  } = useCampaignsSummary({
    accountId: account?.id,
  });
  const campaignAction = useCampaignAction();

  useEffect(() => {
    if (!notificationCampaignId) return;
    handleNotifyCampaignLaunch(lastMessage);
  }, [JSON.stringify(lastMessage)]);

  const handleNotifyCampaignLaunch = async (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'campaign.complete.sync.finished',
        'campaign.complete.sync.failed',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'campaign.complete.sync.finished') {
      await refetch();
      await summaryRefetch();

      toast?.success(t('toast.success'), t('campaign.completed'));
    }

    if (messages?.data?.event === 'campaign.complete.sync.failed') {
      toast?.success(t('toast.success'), t('campaign.not.completed'));
    }

    setCampaignId(undefined);
  };

  const {
    lastMessage: lastMessageDeletion,
    setNotificationParam: setIdForDeletion,
    notificationParam: notificationForDeletion,
    isConnected: isConnectedForDeletion,
  } = useNotifications(client.campaigns.campaignsNotifyUrl);

  useEffect(() => {
    if (!notificationForDeletion) return;
    handleNotifyCampaignDelete(lastMessageDeletion);
  }, [JSON.stringify(lastMessageDeletion)]);

  const handleNotifyCampaignDelete = async (messages: any) => {
    if (
      !messages?.data?.event ||
      !['campaign.delete.finished', 'campaign.delete.error'].includes(
        messages?.data?.event,
      )
    )
      return;

    if (messages?.data?.event === 'campaign.delete.finished') {
      setDeletingCampaignId(null);
      toast?.success(t('toast.success'), t('campaign.deleted'));
      await refetch();
    }

    if (messages?.data?.event === 'campaign.delete.error') {
      setDeletingCampaignId(null);
      toast?.error(t('toast.error'), t('campaign.not.deleted'), 5000);
      await refetch();
    }

    setIdForDeletion(undefined);
  };

  const handleDeleteCampaign = async (campaignId: string) => {
    setDeletingCampaignId(campaignId);
    try {
      const service = account?.isSystem
        ? deleteCampaignTemplate
        : deleteCampaign;
      await service.delete(campaignId);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
      setDeletingCampaignId(null);
    }
  };

  const sendTestEmail = useSendTestEmail();
  const {
    lastMessage: testMailLastMessage,
    setNotificationParam: setJobId,
    notificationParam: jobId,
  } = useNotifications(client.senderProfiles.senderProfilesJobNotifyUrl);

  useEffect(() => {
    if (!jobId) return;
    handleNotifyEmailSent(testMailLastMessage);
  }, [JSON.stringify(testMailLastMessage)]);

  const handleNotifyEmailSent = (messages: any) => {
    if (
      !messages?.data?.event ||
      ![
        'sending-profile.email-sent',
        'sending-profile.email-not-sent',
      ].includes(messages?.data?.event)
    )
      return;

    if (messages?.data?.event === 'sending-profile.email-sent')
      toast?.success(t('toast.success'), t('campaign.testEmail.sent'), 5000);
    if (messages?.data?.event === 'sending-profile.email-not-sent')
      toast?.info(t('toast.info'), t('campaign.testEmail.notSent'), 5000);

    setJobId(undefined);
  };

  const handleSendTestEmail = async (data: SendTestEmailFormValues) => {
    if (!showSendTestEmailForACampaing?.id) return;

    try {
      const response = await sendTestEmail.send({
        email:
          data.emailType === 'custom'
            ? data.email
            : (data.admin?.email as string),
        firstName:
          data.emailType === 'custom' ? data.firstName : data.admin?.firstName,
        lastName:
          data.emailType === 'custom' ? data.lastName : data.admin?.lastName,
        position: data.emailType === 'custom' ? data.position : undefined,
        campaignId: showSendTestEmailForACampaing.id,
        emailTemplateId: showSendTestEmailForACampaing?.emailTemplate?.id,
        sendingProfileId: showSendTestEmailForACampaing?.sendingProfile?.id,
        landingPageId: showSendTestEmailForACampaing?.landingPage?.id,
        encodeEmailTemplateImages:
          showSendTestEmailForACampaing?.meta?.encodeEmailTemplateImages,
      });

      setJobId(response.jobId);
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }

    setShowSendTestEmailForACampaing(null);
  };

  const copyCampaign = useCopyCampaign();
  const copyCampaignTemplate = useCopyCampaignTemplate();
  const handleCopyCampaign = async (campaign: Campaign) => {
    try {
      setCopyState(LoadingStatuses.LOADING);
      const service = account?.isSystem ? copyCampaignTemplate : copyCampaign;

      const response = await service.copy({
        campaignId: campaign?.id,
        account: (account as Account)?.id,
      });
      setCopyState(LoadingStatuses.IDLE);

      if (response && !response?.id) return;

      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id), {
        state: { isTemplate: !!account?.isSystem },
      });
      toast?.success(
        t('toast.success'),
        account?.isSystem
          ? t('campaign.template.copied')
          : t('campaign.copied'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const updateCampaign = useUpdateCampaign();

  const saveCampaign = useSaveCampaign();
  const saveCampaignTemplate = useSaveCampaignTemplate();

  const handleCreateNewCampaign = async ({
    isTemplate,
  }: {
    isTemplate?: boolean;
  }) => {
    try {
      const service = isTemplate ? saveCampaignTemplate : saveCampaign;
      const response = await service.create({
        name: v4(),
        account: account?.id,
      });

      if (!response?.id) return;

      navigate(RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](response?.id), {
        state: { isTemplate },
      });
      toast?.success(t('toast.success'), t('campaign.created'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const getColumns = (): DataTableColumnType[] => {
    const columns: DataTableColumnType[] = [
      {
        field: 'name',
        header: account?.isSystem
          ? t('campaign.template.name')
          : t('campaign.name'),
        sortable: true,
        filterable: true,
        filters: {
          type: FilterTypeEnum.TEXT,
          placeholder: t('generic.name.search'),
        },
        render: (row: Campaign) => row.name,
      },
    ];

    if (account?.isSystem) {
      columns.push({
        field: 'template',
        header: t('generic.template'),
        sortable: false,
        filterable: true,
        filters: { type: FilterTypeEnum.SELECT_YES_NO },
        render: (row: CampaignTemplate) =>
          row.isPublished ? (
            <AppChip label={t('generic.template')} type="primary" />
          ) : (
            <AppChip label={t('campaign.status.draft')} type="secondary" />
          ),
      });
    }

    if (user?.account.isSystem) {
      columns.push(
        {
          field: 'phishingType',
          header: t('campaign.template.phishingType'),
          sortable: false,
          filterable: true,
          filters: {
            type: FilterTypeEnum.SELECT,
            options: getPhishingTypeOptions(t),
          },
          render: (row: Campaign) =>
            row?.meta?.phishingType ? (
              t(`campaign.type.${row.meta.phishingType}`)
            ) : (
              <div className="group-row">&#8212;</div>
            ),
        },
        {
          field: 'phishingSign',
          header: t('campaign.template.phishingSign'),
          sortable: false,
          filterable: true,
          filters: {
            type: FilterTypeEnum.SELECT,
            options: getPhishingSignOptions(t),
          },
          render: (row: Campaign) =>
            row?.meta?.phishingSign ? (
              t(`campaign.template.${row.meta.phishingSign}`)
            ) : (
              <div className="group-row">&#8212;</div>
            ),
        },
      );
    }

    if (!account?.isSystem) {
      columns.push(
        {
          field: 'cloneOfName',
          header: t('campaign.template.name'),
          sortable: false,
          filterable: true,
          filters: {
            type: FilterTypeEnum.TEXT,
          },
          render: (row: Campaign) =>
            row.meta?.cloneOfName ? (
              row.meta.cloneOfName
            ) : (
              <div className="group-row">&#8212;</div>
            ),
        },
        ...(can(Actions.READ, Subjects.ACCOUNTS)
          ? [
              {
                field: 'isAutoPhishing',
                header: t('campaign.auto.phishing'),
                sortable: false,
                filterable: true,
                filters: {
                  type: FilterTypeEnum.SELECT_YES_NO,
                },
                render: (row: Campaign) =>
                  row.isAutoPhishing ? (
                    <AppChip label={t('dialog.yes')} type="primary" />
                  ) : (
                    <AppChip label={t('dialog.no')} type="secondary" />
                  ),
              },
            ]
          : []),
        {
          field: 'status',
          header: t('generic.status'),
          sortable: true,
          filterable: true,
          filters: {
            field: FilterNamesEnum.CAMPAIGNS_BY_STATUS,
            type: FilterTypeEnum.MULTI_SELECT,
            placeholder: t('generic.status.search'),
            options: getCampaignStatusOptions(t),
          },
          render: (row: Campaign) =>
            getStatus(
              row.status,
              !!row?.useDynamicTarget &&
                row.status === CampaignStatusesEnum.SCHEDULED,
              t,
            ),
        },
        {
          field: 'launchDate',
          header: t('generic.launchDate'),
          sortable: true,
          filterable: false,
          render: (row: Campaign) => (
            <FlexContainer minWidth="max-content" justify="flex-start">
              {row?.launchDate ? (
                <FormatDate date={row?.launchDate} />
              ) : (
                t('campaing.no.LaunchDate')
              )}
            </FlexContainer>
          ),
        },
        {
          field: 'summary.total',
          header: (
            <Tooltip
              position="top"
              target="th.users-icon"
              content={t('users')}
            />
          ),
          iconLabel: t('users'),
          headerClassName: 'users-icon',
          sortable: true,
          filterable: true,
          filters: {
            type: FilterTypeEnum.SELECT_YES_NO,
            field: FilterNamesEnum.CAMPAIGN_DYNAMIC_TARGET,
            label: t('campaign.dynamicTarget'),
            placeholder: t('generic.select'),
          },
          render: (row: Campaign) =>
            row?.summary?.total ? row.summary.total : 0,
        },
        {
          field: 'summary.emailsSent',
          header: (
            <Tooltip
              position="top"
              target="th.email-sent-icon"
              content={t('campaign.emailSent')}
            />
          ),
          iconLabel: t('campaign.emailSent'),
          headerClassName: 'email-sent-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) =>
            row?.summary?.emailsSent ? row.summary.emailsSent : 0,
        },
      );

      if (canUseFeature(Subjects.EMAIL_OPEN_EVENTS)) {
        columns.push({
          field: 'summary.emailsOpen',
          header: (
            <Tooltip
              position="top"
              target="th.email-opened-icon"
              content={t('campaign.emailOpened')}
            />
          ),
          iconLabel: t('campaign.emailOpened'),
          headerClassName: 'email-opened-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) =>
            row?.summary?.emailsOpen ? row.summary.emailsOpen : 0,
        });
      }

      columns.push(
        {
          field: 'summary.clickedLinks',
          header: (
            <Tooltip
              position="top"
              target="th.clicked-link-icon"
              content={t('campaign.clicked')}
            />
          ),
          iconLabel: t('campaign.clicked'),
          headerClassName: 'clicked-link-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) =>
            row?.summary?.clickedLinks ? row.summary.clickedLinks : 0,
        },
        {
          field: 'summary.submittedData',
          header: (
            <Tooltip
              position="top"
              target="th.submitted-data-icon"
              content={t('campaign.submitted')}
            />
          ),
          iconLabel: t('campaign.submitted'),
          headerClassName: 'submitted-data-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) =>
            row?.summary?.submittedData ? row.summary.submittedData : 0,
        },
      );

      if (canUseFeature(Subjects.OUTLOOK_PHISHING_EMAIL_REPORTING)) {
        columns.push({
          field: 'summary.emailsReported',
          header: (
            <Tooltip
              position="top"
              target="th.email-reported-icon"
              content={t('campaign.email.reported')}
            />
          ),
          iconLabel: t('campaign.email.reported'),
          headerClassName: 'email-reported-icon',
          sortable: true,
          filterable: false,
          render: (row: Campaign) =>
            row?.summary?.emailsReported ? row.summary.emailsReported : 0,
        });
      }
    }

    columns.push({
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center' as const,
      },
      render: (row: Campaign) => {
        if (deletingCampaignId === row.id) {
          return (
            <FlexContainer>
              <ProgressSpinner style={{ width: '24px', height: '24px' }} />
            </FlexContainer>
          );
        }
        return (
          <DataTableActions
            disabled={menuItems(row).length < 1}
            menuItems={menuItems(row)}
          />
        );
      },
    });

    return columns;
  };

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const defaultVisibleColumns = getColumns()
    .filter(
      (column) =>
        !['phishingSign', 'phishingType', 'isAutoPhishing'].includes(
          column.field,
        ),
    )
    .map((column) => column.field);
  const alwaysVisibleColumns = ['name', 'actions'];
  //

  const menuItems = (campaign: Campaign) => {
    const menu: MenuItem[] = [];

    if (
      !user?.account?.isSystem &&
      campaign.status === CampaignStatusesEnum.DRAFT
    ) {
      menu.push({
        label: t('camapign.testEmail.send'),
        icon: 'pi pi-envelope',
        command: () => setShowSendTestEmailForACampaing(campaign),
      });
    }

    switch (true) {
      case campaign.status === CampaignStatusesEnum.ACTIVE:
        if (
          can(Actions.UPDATE, Subjects.CAMPAIGNS) &&
          can(Actions.READ, Subjects.ACCOUNTS)
        ) {
          menu.push({
            label: t('generic.complete'),
            icon: 'pi pi-chevron-circle-down',
            command: () =>
              setDialogData({
                show: true,
                type: 'confirmation',
                header: t('campaign.complete'),
                message: t('campaign.complete.confirm'),
                onAccept: async () => {
                  setCampaignId(campaign.id);

                  // Wait for a notify connection to establish
                  await new Promise((resolve) => {
                    const intervalId = setInterval(() => {
                      if (isConnected.current) {
                        clearInterval(intervalId);
                        resolve(true);
                      }
                    }, 100);
                  });
                  try {
                    if (isConnected.current) {
                      await campaignAction.updateStatus({
                        campaignId: campaign.id,
                        action: CampaignActionsEnum.COMPLETE,
                      });

                      await refetch();
                      await summaryRefetch();
                    }
                  } catch (e) {
                    handleAxiosError(e as Error | AxiosError, toast);
                  }
                },
              }),
          });
        }
        break;
      case campaign.status === CampaignStatusesEnum.DRAFT:
      case campaign.type === CampaignType.TEMPLATE:
        if (
          can(Actions.UPDATE, Subjects.CAMPAIGNS) &&
          can(Actions.READ, Subjects.ACCOUNTS)
        ) {
          menu.push({
            label: t('generic.edit'),
            icon: 'pi pi-pencil',
            command: () => {
              navigate(
                RedirectPaths[RedirectPathsEnum.CAMPAIGNS_EDIT](campaign.id),
                {
                  state: { isTemplate: !!account?.isSystem },
                },
              );
            },
          });
        }
        break;
      default:
    }

    if (can(Actions.CREATE, Subjects.CAMPAIGNS)) {
      menu.push({
        label: t('generic.copy'),
        icon:
          copyState === LoadingStatuses.LOADING
            ? 'pi pi-spinner'
            : 'pi pi-copy',
        command: async () => await handleCopyCampaign(campaign),
      });
    }

    if (
      campaign.status &&
      can(Actions.UPDATE, Subjects.CAMPAIGNS) &&
      !account?.isSystem &&
      campaign.status === CampaignStatusesEnum.DRAFT &&
      campaign.isAutoPhishing
    ) {
      menu.push({
        label: campaign?.isWhitelisted
          ? t('campaign.whitelist.confirmed')
          : t('campaign.confirm.whitelisted'),
        icon: 'pi pi-check',
        disabled: campaign?.isWhitelisted,
        command: () =>
          setDialogData({
            show: true,
            type: 'confirmation',
            header: t('campaign.confirm.whitelisted'),
            message: t('campaign.confirm.whitelisted.warn'),
            onAccept: async () => {
              try {
                await updateCampaign.update({
                  campaignId: campaign.id,
                  updates: { isWhitelisted: true },
                });

                await refetch();

                toast?.success(
                  t('toast.success'),
                  t('campaign.confirm.whitelisted.success'),
                );
              } catch (e) {
                handleAxiosError(e as Error | AxiosError, toast);
              }
            },
          }),
      });
    }

    if (
      campaign.status &&
      ![
        CampaignStatusesEnum.DRAFT,
        CampaignStatusesEnum.PENDING_LAUNCH,
        CampaignStatusesEnum.FAILED_LAUNCH,
      ].includes(campaign.status)
    ) {
      menu.push(
        ...[
          {
            label: t('generic.reports'),
            icon: 'pi pi-chart-line',
            command: () => {
              navigate(
                RedirectPaths[RedirectPathsEnum.CAMPAIGNS_REPORT](campaign.id),
              );
            },
          },
        ],
      );
    }

    if (
      (can(Actions.DELETE, Subjects.CAMPAIGNS) &&
        can(Actions.READ, Subjects.ACCOUNTS)) ||
      (can(Actions.DELETE, Subjects.CAMPAIGNS) &&
        cannot(Actions.READ, Subjects.ACCOUNTS) &&
        campaign.isAutoPhishing &&
        (campaign.status === CampaignStatusesEnum.DRAFT ||
          campaign.status === CampaignStatusesEnum.SCHEDULED))
    ) {
      menu.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: async () =>
          setDialogData({
            show: true,
            type: 'confirmation',
            header: account?.isSystem
              ? t('campaign.template.delete')
              : t('campaign.delete'),
            message: account?.isSystem
              ? t('campaign.template.delete.confirm')
              : t('campaign.delete.confirm'),
            onAccept: async () => {
              if (account?.isSystem) {
                try {
                  await handleDeleteCampaign(campaign.id);
                } catch (e) {
                  handleAxiosError(e as Error | AxiosError, toast);
                } finally {
                  toast?.success(
                    t('toast.success'),
                    t('campaign.template.deleted'),
                  );
                  setDeletingCampaignId(null);
                  await refetchCampaignTemplates();
                }
                return;
              }

              setIdForDeletion(campaign.id);

              // Wait for a notify connection to establish
              await new Promise((resolve) => {
                const intervalId = setInterval(() => {
                  if (isConnectedForDeletion.current) {
                    clearInterval(intervalId);
                    resolve(true);
                  }
                }, 100);
              });
              try {
                if (isConnectedForDeletion.current) {
                  await handleDeleteCampaign(campaign.id);
                }
              } catch (e) {
                handleAxiosError(e as Error | AxiosError, toast);
              }
            },
          }),
      });
    }

    return menu?.length ? menu : [];
  };

  const dropdownButtonOptions: MenuItem[] = [
    {
      label: t('campaigns.fromTemplate'),
      icon: 'pi pi-table',
      command: () => setShowCampaignTemplatesModal(true),
    },
    {
      label: t('campaigns.fromScratch'),
      icon: 'pi pi-stop',
      command: () => handleCreateNewCampaign({ isTemplate: false }),
    },
  ];

  const toolbar = (
    <DataTableToolbar justify="space-between">
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
        flex="1 1 50%"
      >
        <DataTableFilters
          filters={getFiltersFromColumns(getColumns())}
          onFilter={onFilter}
          tableName={
            account?.isSystem
              ? TableNamesEnum.CAMPAIGNS_LIST_SYSTEM
              : TableNamesEnum.CAMPAIGNS_LIST_CUSTOMER
          }
        />
      </FlexContainer>
      <FlexContainer
        gap={12}
        justify="flex-end"
        minWidth="min-content"
        flex="1 1 45%"
        wrap="wrap"
      >
        {can(Actions.CREATE, Subjects.CAMPAIGNS) && (
          <>
            {account?.isSystem ? (
              <AppButton
                label={t('campaign.template.new')}
                severity="secondary"
                onClick={() => handleCreateNewCampaign({ isTemplate: true })}
                state={queryStateConverter(saveCampaign)}
              />
            ) : (
              <AppDropdownButton
                menuId="campaign-create"
                label={t('campaign.create')}
                items={dropdownButtonOptions}
                severity="secondary"
                state={queryStateConverter(saveCampaign)}
              />
            )}
          </>
        )}
        {isTable && (
          <DataTableColumnsMultiselect
            columns={getColumns()}
            tableName={
              account?.isSystem
                ? TableNamesEnum.CAMPAIGNS_LIST_SYSTEM
                : TableNamesEnum.CAMPAIGNS_LIST_CUSTOMER
            }
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            defaultVisibleColumns={defaultVisibleColumns}
            alwaysVisibleColumns={alwaysVisibleColumns}
          />
        )}
        <AppSelectPreviewType
          tableName="campaignsTable"
          accountId={account?.id}
          userId={user?.id}
          selectedPreviewType={selectedPreviewType}
          setSelectedPreviewType={setSelectedPreviewType}
        />
      </FlexContainer>
    </DataTableToolbar>
  );

  return (
    <>
      <StyledDiv>
        {isTable ? (
          <DataTable
            data={data?.result}
            count={data?.count as number}
            isLoading={areCampaignsLoading || areCampaignTemplatesLoading}
            toolbar={toolbar}
            columns={getColumns()}
            visibleColumns={visibleColumns}
            rows={take}
            skip={skip}
            onPage={onPage}
            onSort={onSort}
            sort={sort}
          />
        ) : (
          <GridTable
            count={data?.count}
            take={take}
            skip={skip}
            toolbarContent={toolbar}
            onPageChange={onGridPageChange}
            onRowsChange={onGridRowChange}
            isLoading={areCampaignsLoading || areCampaignTemplatesLoading}
            rowsPerPage={[8, 16]}
          >
            {!areCampaignsLoading &&
              !areCampaignsLoading &&
              !!data?.result.length && (
                <>
                  <ThumbnailsGenerationNotify
                    refetch={refetch}
                    templates={data?.result.map(
                      (campaign: Campaign) => campaign.emailTemplate,
                    )}
                  />
                  <StyledCardGridViewContainer>
                    {data?.result.map(
                      (campaign: Campaign | CampaignTemplate) => (
                        <ImageCard
                          key={campaign.id}
                          id={campaign.id}
                          title={campaign.name}
                          status={
                            !account?.isSystem
                              ? displayCampaignStatus(t, campaign.status)
                              : undefined
                          }
                          author={displayCreatedBy(t, campaign.createdBy)}
                          thumbnail={
                            campaign.emailTemplate?.thumbnail?.signedUrl
                          }
                          templateType="email"
                          interactionType="withActions"
                          disabledActions={menuItems(campaign).length < 1}
                          actions={menuItems(campaign)}
                          isTemplate={
                            account?.isSystem
                              ? (campaign as CampaignTemplate).isPublished
                              : false
                          }
                        />
                      ),
                    )}
                  </StyledCardGridViewContainer>
                </>
              )}
          </GridTable>
        )}
      </StyledDiv>
      <SendTestEmailModal
        email={user?.email as string}
        visible={!!showSendTestEmailForACampaing}
        onSubmit={handleSendTestEmail}
        onHide={() => setShowSendTestEmailForACampaing(null)}
        extended={false}
        withName
      />
      <CampaignTemplatesModal
        visible={showCampaignTemplatesModal}
        onHide={() => setShowCampaignTemplatesModal(false)}
      />
    </>
  );
};
