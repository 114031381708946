import { SystemEmailsTemplatesFormValues } from '@/client/system-emails';
import { availableLanguagesDropdown } from '@/common/constants/languages';
import {
  FormikDropdown,
  FormikInput,
  FormikSwitch,
  FormikTextarea,
} from '@/components/form';
import { systemEmailsTemplatesSchema } from '@/system-settings';
import { FlexContainer } from '@/ui/styled-ui';
import { emailTypeOptions } from '@/utils/helpers';
import { preventDropdownBrowserTranslate } from '@/utils/helpers/ui.helper';
import { Field, Form, Formik } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SystemEmailsTemplatesFormProps = {
  initialValues: SystemEmailsTemplatesFormValues;
  state?: 'idle' | 'loading' | 'error';
  isCreate?: boolean;
  onInputChange: (values: SystemEmailsTemplatesFormValues) => void;
  onValidateForm: (isValid: boolean) => void;
};

export const SystemEmailTemplateInformationForm: React.FC<
  SystemEmailsTemplatesFormProps
> = ({ initialValues, onInputChange, isCreate, onValidateForm }) => {
  const { t } = useTranslation();
  const [isValidState, setIsValidState] = useState(!isCreate);

  useEffect(() => {
    // Comunicate the validity state to the wrapper component
    onValidateForm(isValidState);
  }, [isValidState]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
      validationSchema={systemEmailsTemplatesSchema(t)}
      validateOnMount
    >
      {({ values, isValid, setFieldValue }) => {
        useEffect(() => {
          setIsValidState(isValid);
        }, [isValid]);

        useEffect(() => {
          onInputChange(values);
        }, [values]);

        return (
          <Form>
            <FlexContainer
              height="100%"
              justify="space-between"
              align="flex-start"
              gap={24}
            >
              <div className="w-6">
                <div className="field">
                  <Field
                    id="type"
                    name="type"
                    label={t('system.email.templates.emailType')}
                    className="w-full"
                    placeholder={t(
                      'system.email.templates.emailType.placeholder',
                    )}
                    onChange={(e: DropdownChangeEvent) =>
                      setFieldValue('type', e.value)
                    }
                    options={emailTypeOptions(t)}
                    component={FormikDropdown}
                    data-testid="campaign-form-period-step-emailing-type"
                    required
                  />
                </div>
                <div className="field">
                  <Field
                    id="language"
                    name="language"
                    label={t('generic.lang')}
                    className="w-full"
                    placeholder={t(
                      'system.email.templates.language.placeholder',
                    )}
                    onChange={(e: DropdownChangeEvent) =>
                      setFieldValue('language', e.value)
                    }
                    options={availableLanguagesDropdown}
                    data-testid="campaign-form-period-step-emailing-type"
                    component={FormikDropdown}
                    required
                    {...preventDropdownBrowserTranslate}
                  />
                </div>
                <div className="field-checkbox mt-3">
                  <Field
                    inputId="active"
                    name="active"
                    label={t('generic.active')}
                    component={FormikSwitch}
                  />
                </div>
              </div>
              <div className="w-6">
                <div className="field">
                  <Field
                    id="subject"
                    name="subject"
                    label={t('generic.subject')}
                    className="w-full"
                    placeholder={t(
                      'system.email.templates.subject.placeholder',
                      { length: 190 },
                    )}
                    onChange={(event: any) =>
                      setFieldValue('subject', event.target.value)
                    }
                    component={FormikInput}
                    data-testid="campaign-form-period-step-name"
                    required
                  />
                </div>
                <div className="field">
                  <Field
                    id="text"
                    name="text"
                    label={t('system.email.templates.description')}
                    rows={6}
                    autoResize
                    className="w-full"
                    placeholder={t('system.email.templates.description')}
                    component={FormikTextarea}
                    onChange={(event: any) =>
                      setFieldValue('text', event.target.value)
                    }
                    data-testid="campaign-form-period-step-name"
                    required
                  />
                </div>
              </div>
            </FlexContainer>
          </Form>
        );
      }}
    </Formik>
  );
};
