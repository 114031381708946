import { languageDisplayLabel, LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { AppBreadCrumbTemplate } from '@/app/AppBreadCrumbTemplate';
import { client } from '@/client';
import { CourseAttempt, CourseStatusAttemptEnum } from '@/client/courses';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { TranslationFunctionType } from '@/common/types';
import { useCourse } from '@/hooks/query';
import { useAppSelector } from '@/hooks/store';
import { useToast } from '@/hooks/useToast';
import PagePlaceholderImage from '@/images/placeholders/page-template.svg';
import { selectCurrentUser } from '@/store/features/users';
import { AppBreadCrumb } from '@/ui/breadcrumb';
import { AppButton } from '@/ui/buttons';
import { FormatDate } from '@/ui/date';
// import AppProgressBar from '@/ui/progressbar/AppProgressBar';
import { FlexContainer } from '@/ui/styled-ui';
import { BackButton } from '@/ui/styled-ui/BackButton';
import {
  // getCourseAttemptProgress,
  getCourseLanguages,
  getTranslationByLanguage,
} from '@/utils/helpers';
import { AxiosError } from 'axios';
import { MenuItem } from 'primereact/menuitem';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const StyledFlexContainer = styled(FlexContainer)`
  border-radius: var(--default-border-radius);
  background: #f6f6f3;
  min-height: 400px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledImgWrapper = styled.div`
  display: flex;
  align-self: stretch;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 50%;

  .image-overlay {
    position: absolute;
    background: var(--orange-faint);
    opacity: 0;
    transition: all 0.4s;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.4s;
  }

  &:hover,
  &:focus-within {
    .image-overlay {
      opacity: 1;
      cursor: pointer;
    }

    &:before {
      background: var(--black-main);
      opacity: 0.3;
    }
  }

  @media screen and (max-width: 767px) {
    height: 250px;
    width: 100%;

    .image-overlay {
      opacity: 1;
    }

    &:before {
      background: var(--black-main);
      opacity: 0.3;
    }
  }
`;

const StyledInfoDiv = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding: var(--default-padding);
  word-break: break-word;
  width: 50%;

  > h1 {
    font-size: var(--heading-large-font-size);
    line-height: var(--heading-large-line-height);
  }

  .course-description {
    margin-top: var(--medium-padding);
    padding: 0;
  }

  a {
    text-decoration: none;
    color: var(--red-main);
    &:hover,
    &:visited {
      color: var(--red-dark);
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding-top: 0;

    h1 {
      font-size: var(--heading-small-font-size);
      line-height: var(--heading-small-line-height);
    }
  }
`;

const StyledDate = styled.div`
  color: var(--gray-darker);
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: 500;
`;
/*
const StyledAppProgressBar = styled(AppProgressBar)`
  flex: 1;
  margin-top: auto;
`;
*/

const StyledAppButton = styled(AppButton)`
  &.p-button.p-button-icon-only {
    width: var(--small-button-height);
    height: var(--small-button-height);
    padding: 0 0 0 3px;
    margin-left: var(--small-padding);

    .p-button-icon {
      font-size: var(--small-font-size);
    }

    @media screen and (max-width: 812px) {
      width: calc(1.2 * var(--small-button-height));
      height: calc(1.2 * var(--small-button-height));
    }
  }
`;
const StyledAppButtonLarge = styled(AppButton)`
  &.p-button.p-button-icon-only {
    width: 100px;
    height: 100px;
    padding: 0 0 0 12px;
    border-radius: 50%;

    .p-button-icon {
      font-size: var(--heading-large-font-size);
    }
  }
`;

export const ScormCourseInfoPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: courseId } = useParams();
  const toast = useToast();
  const currentUser = useAppSelector(selectCurrentUser);

  const [currentScormCourseAttempt, setCurrentScormCourseAttempt] = useState<
    CourseAttempt | undefined
  >(undefined);

  // Fetch course data
  const { course, isLoading: isCourseLoading } = useCourse({
    courseId: courseId,
    staleTime: Infinity,
  });

  // Fetch attempt or create a new one/
  useEffect(() => {
    getCourseAttempt();
  }, [course]);

  const getCourseAttempt = async () => {
    if (!course) return;

    try {
      const attempt = await client.learnerCourses.getLearnerCourseLastAttempt(
        course.id,
      );

      setCurrentScormCourseAttempt(attempt);
    } catch (e: any) {
      if (e?.statusCode === 404) {
        createLearnerCourseNewAttempt();
      } else {
        handleAxiosError(e as AxiosError, toast);
      }
    }
  };

  const createLearnerCourseNewAttempt = async (redirectToAttempt?: boolean) => {
    if (course?.id) {
      try {
        const newAttempt =
          await client.learnerCourses.createLearnerCourseNewAttempt(course.id);
        setCurrentScormCourseAttempt(newAttempt);

        if (redirectToAttempt) {
          navigate(
            RedirectPaths[RedirectPathsEnum.COURSE_PLAY](
              course?.id,
              newAttempt?.id,
            ),
          );
        }
      } catch (e: any) {
        handleAxiosError(e as AxiosError, toast);
      }
    }
  };

  const navigateToCourseAttempt = async () => {
    if (!course) return;

    if (
      !currentScormCourseAttempt ||
      currentScormCourseAttempt.status !== CourseStatusAttemptEnum.IN_PROGRESS
    ) {
      await createLearnerCourseNewAttempt(true);
    } else {
      navigate(
        RedirectPaths[RedirectPathsEnum.COURSE_PLAY](
          course?.id,
          currentScormCourseAttempt?.id,
        ),
        { state: location.state },
      );
    }
  };

  const getBreadcrumbs = (t: TranslationFunctionType): MenuItem[] => [
    {
      label: t('courses'),
      url:
        location?.state?.from ||
        RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
      template: AppBreadCrumbTemplate,
    },
    {
      label: course?.name,
      url: RedirectPaths[RedirectPathsEnum.COURSE_INFO](course?.id ?? ''),
      template: AppBreadCrumbTemplate,
      data: { state: location?.state },
    },
  ];

  const renderLanguageList = (languages: string) => {
    const sortedLanguages = languages.split(', ');
    return (
      <>
        {sortedLanguages.map((lang, index) => {
          // Get the corresponding Enum value
          const enumValue = LanguagesEnum[lang as keyof typeof LanguagesEnum];
          return (
            <React.Fragment key={index}>
              <Tooltip
                target={`#${lang}-lang`}
                position="top"
                content={languageDisplayLabel[enumValue]}
                my="center bottom-10"
              />
              <span id={`${lang}-lang`}>
                {lang}
                {index < sortedLanguages.length - 1 ? ', ' : ''}
              </span>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const isCompleted =
    currentScormCourseAttempt?.status === CourseStatusAttemptEnum.COMPLETED;

  return (
    <FlexContainer height="100%" direction="column" justify="flex-start">
      {isCourseLoading && (
        <FlexContainer direction="column" className="mt-5 flex-1">
          <ProgressSpinner />
          <h3>{t('course.info.loading')}</h3>
        </FlexContainer>
      )}
      {!isCourseLoading && course && (
        <FlexContainer direction="column" align="flex-start" gap={24}>
          <AppBreadCrumb model={getBreadcrumbs(t)} className="no-space" />
          <BackButton
            to={
              location?.state?.from ||
              RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE]()
            }
            text={t('button.back')}
          />
          <StyledFlexContainer gap={24} align="flex-start">
            <StyledImgWrapper
              style={{
                backgroundImage: `url(${CSS.escape(
                  course?.courseThumbnail?.signedUrl
                    ? course?.courseThumbnail?.signedUrl
                    : PagePlaceholderImage,
                )}`,
              }}
            >
              <div className=" image-overlay">
                <StyledAppButtonLarge
                  icon="pi pi-caret-right"
                  size="lg"
                  ariaLabel={
                    !currentScormCourseAttempt
                      ? t('course.start')
                      : t('course.resume')
                  }
                  severity={isCompleted ? 'secondary' : undefined}
                  onClick={navigateToCourseAttempt}
                />
              </div>
            </StyledImgWrapper>
            <StyledInfoDiv>
              <h1 className="m-0">
                {
                  getTranslationByLanguage(
                    course?.courseTranslations,
                    currentUser?.language
                      ? (currentUser?.language as LanguagesEnum)
                      : LanguagesEnum.EN,
                  )?.name
                }
              </h1>
              <div
                className="course-description ql-editor"
                dangerouslySetInnerHTML={{
                  __html:
                    getTranslationByLanguage(
                      course?.courseTranslations,
                      currentUser?.language
                        ? (currentUser?.language as LanguagesEnum)
                        : LanguagesEnum.EN,
                    )?.description ?? '',
                }}
              />
              <span className="secondary-text mb-3">
                <span>{t('courses.available.languages')}: </span>
                {renderLanguageList(
                  getCourseLanguages(course?.courseLanguages),
                )}
              </span>
              {(course?.courseCreatedAt || course?.courseLastUpdatedAt) && (
                <FlexContainer gap={24} align="flex-start" justify="flex-start">
                  {course.courseCreatedAt && (
                    <StyledDate>
                      <span>{t('generic.created')}: </span>
                      <FormatDate date={course.courseCreatedAt} />
                    </StyledDate>
                  )}
                  {course.courseLastUpdatedAt && (
                    <StyledDate>
                      <span>{t('generic.updated')}: </span>
                      <FormatDate date={course.courseLastUpdatedAt} />
                    </StyledDate>
                  )}
                </FlexContainer>
              )}
              {/* TODO: Uncomment if we have progress bar */}
              <FlexContainer justify="flex-end" className="mt-auto">
                {/* <StyledAppProgressBar
                  value={getCourseAttemptProgress(currentScormCourseAttempt)}
                  isPurple={isCompleted}
                /> */}
                <StyledAppButton
                  icon="pi pi-caret-right"
                  size="sm"
                  ariaLabel={
                    !currentScormCourseAttempt
                      ? t('course.start')
                      : t('course.resume')
                  }
                  severity={isCompleted ? 'secondary' : undefined}
                  onClick={navigateToCourseAttempt}
                />
              </FlexContainer>
            </StyledInfoDiv>
          </StyledFlexContainer>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};
