import { BranchStatusEnum, UserStatusEnum } from '@/client/courses';
import { TranslationFunctionType } from '../types';

export const userStatusOptions = (t: TranslationFunctionType) => [
  {
    label: t('course.attempt.status.inProgress'),
    value: UserStatusEnum.IN_PROGRESS,
  },
  {
    label: t('course.attempt.status.completed'),
    value: UserStatusEnum.COMPLETED,
  },
  {
    label: t('course.attempt.status.failed'),
    value: UserStatusEnum.FAILED,
  },
  {
    label: t('course.attempt.status.enrolled'),
    value: UserStatusEnum.ENROLLED,
  },
  {
    label: t('course.attempt.status.scheduled'),
    value: UserStatusEnum.SCHEDULED,
  },
  {
    label: t('course.attempt.status.notScheduled'),
    value: UserStatusEnum.NOT_SCHEDULED,
  },
];

export const branchStatusOptions = (t: TranslationFunctionType) => [
  {
    label: t('course.attempt.status.enrolled'),
    value: BranchStatusEnum.ENROLLED,
  },
  {
    label: t('course.attempt.status.scheduled'),
    value: BranchStatusEnum.SCHEDULED,
  },
  {
    label: t('course.attempt.status.notScheduled'),
    value: BranchStatusEnum.NOT_SCHEDULED,
  },
];
