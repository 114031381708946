import { EmailType } from '@/client/system-emails';
import { TranslationFunctionType } from '@/common/types';

export enum TemplatePlaceholders {
  ID = '{{.RId}}',
  FIRST_NAME = '{{.FirstName}}',
  LAST_NAME = '{{.LastName}}',
  POSITION = '{{.Position}}',
  EMAIL = '{{.Email}}',
  SENDER = '{{.From}}',
  TRACKING_URL = '{{.TrackingURL}}',
  TRACKER = '{{.Tracker}}',
  URL = '{{.URL}}',
  BASE_URL = '{{.BaseURL}}',
  HONEYPOT_LINK = '{{.HoneypotLink}}',
}

export const templateBuilderLegend = (t: TranslationFunctionType) => [
  {
    clipboardValue: TemplatePlaceholders.ID,
    clipboardText: t('templates.legend.uniqueId'),
  },
  {
    clipboardValue: TemplatePlaceholders.FIRST_NAME,
    clipboardText: t('templates.legend.firstName'),
  },
  {
    clipboardValue: TemplatePlaceholders.LAST_NAME,
    clipboardText: t('templates.legend.lastName'),
  },
  {
    clipboardValue: TemplatePlaceholders.POSITION,
    clipboardText: t('templates.legend.position'),
  },
  {
    clipboardValue: TemplatePlaceholders.EMAIL,
    clipboardText: t('templates.legend.emailAdress'),
  },
  {
    clipboardValue: TemplatePlaceholders.SENDER,
    clipboardText: t('templates.legend.spoofSender'),
  },
  {
    clipboardValue: TemplatePlaceholders.TRACKING_URL,
    clipboardText: t('templates.legend.trackingUrl'),
  },
  {
    clipboardValue: TemplatePlaceholders.TRACKER,
    clipboardText: t('templates.legend.tracker'),
  },
  {
    clipboardValue: TemplatePlaceholders.URL,
    clipboardText: t('templates.legend.url'),
  },
  {
    clipboardValue: TemplatePlaceholders.BASE_URL,
    clipboardText: t('templates.legend.baseUrl'),
  },
  {
    clipboardValue: TemplatePlaceholders.HONEYPOT_LINK,
    clipboardText: t('templates.legend.honeypot'),
  },
];

export enum EmailPlaceholders {
  FULL_NAME = '{{.FullName}}',
  FIRST_NAME = '{{.FirstName}}',
  LAST_NAME = '{{.LastName}}',
  USERNAME = '{{.Username}}',
  EMAIL = '{{.Email}}',
  ACCOUNT_NAME = '{{.AccountName}}',
  LOGO_URL = '{{.LogoUrl}}',
  PLATFORM_NAME = '{{.PlatformName}}',
  PLATFORM_PHONE = '{{.PlatformPhone}}',
  PLATFORM_EMAIL = '{{.PlatformEmail}}',
  LOGIN_URL = '{{.LoginUrl}}',
  PASSWORD = '{{.Password}}',
  SECURITY_CULTURE_URL = '{{.SecurityCultureURL}}',
}

export const TemplateSystemEmailsTemplatesBuilderLegend = (
  t: TranslationFunctionType,
  emailType?: EmailType,
) => {
  let clipboardValues = [
    {
      clipboardValue: EmailPlaceholders.FULL_NAME,
      clipboardText: t('templates.legend.fullName'),
    },
    {
      clipboardValue: EmailPlaceholders.FIRST_NAME,
      clipboardText: t('templates.legend.firstName'),
    },
    {
      clipboardValue: EmailPlaceholders.LAST_NAME,
      clipboardText: t('templates.legend.lastName'),
    },
    {
      clipboardValue: EmailPlaceholders.USERNAME,
      clipboardText: t('templates.legend.username'),
    },
    {
      clipboardValue: EmailPlaceholders.EMAIL,
      clipboardText: t('templates.legend.emailAdress'),
    },
    {
      clipboardValue: `<img src=${EmailPlaceholders.LOGO_URL} alt="Logo" />`,
      clipboardText: t('templates.legend.logo'),
    },
    {
      clipboardValue: EmailPlaceholders.PLATFORM_NAME,
      clipboardText: t('templates.legend.platformName'),
    },
    {
      clipboardValue: EmailPlaceholders.PLATFORM_PHONE,
      clipboardText: t('templates.legend.platformPhone'),
    },
    {
      clipboardValue: EmailPlaceholders.PLATFORM_EMAIL,
      clipboardText: t('templates.legend.platformEmail'),
    },
  ];

  if (
    [
      EmailType.INCOMPLETE_COURSE_REMINDER,
      EmailType.COURSE_ENROLLMENT,
      EmailType.COURSE_AVAILABILITY,
      EmailType.WELCOME,
      EmailType.ACCOUNT_MIGRATION,
      EmailType.WELCOME_SSO,
      EmailType.ACCOUNT_MIGRATION_SSO,
      EmailType.WELCOME_FREE_TRIAL,
    ].includes(emailType as EmailType)
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: EmailPlaceholders.LOGIN_URL,
        clipboardText:
          emailType === EmailType.WELCOME ||
          emailType === EmailType.WELCOME_FREE_TRIAL
            ? t('templates.legend.loginUrl.prefix')
            : t('templates.legend.loginUrl'),
      },
    ];
  }

  if (
    [
      EmailType.WELCOME,
      EmailType.ACCOUNT_MIGRATION,
      EmailType.WELCOME_FREE_TRIAL,
    ].includes(emailType as EmailType)
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: EmailPlaceholders.PASSWORD,
        clipboardText: t('templates.legend.tempPassword'),
      },
    ];
  }

  if (
    [
      EmailType.INCOMPLETE_COURSE_REMINDER,
      EmailType.COURSE_ENROLLMENT,
    ].includes(emailType as EmailType)
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue:
          (emailType as EmailType) === EmailType.COURSE_ENROLLMENT
            ? `{{#each courses}} {{name}} {{#if hasDueDate }}- ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }} {{/each}}`
            : `{{#each courses}} {{name}} - {{numberOfDays}} ${t(
                'generic.units.days',
              )} {{#if isOverdue}}- ${t(
                'system.email.overDueBy',
              )} {{overdueDays}} ${t(
                'generic.units.days',
              )} {{else}} {{#if hasDueDate }}- ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }} {{/if }} {{/each}}`,
        clipboardText: t('templates.legend.coursesAsText'),
      },
      {
        clipboardValue:
          (emailType as EmailType) === EmailType.COURSE_ENROLLMENT
            ? `<ul>{{#each courses}}<li><a href="{{url}}">{{name}}</a> {{#if hasDueDate }} - ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }}</li>{{/each}}</ul>`
            : `<ul>{{#each courses}}<li><a href="{{url}}">{{name}}</a> - {{numberOfDays}} ${t(
                'generic.units.days',
              )} {{#if isOverdue}}- ${t(
                'system.email.overDueBy',
              )} {{overdueDays}} ${t(
                'generic.units.days',
              )} {{else}} {{#if hasDueDate }}- ${t(
                'system.email.dueDate',
              )}: {{dueDate}} {{/if }} {{/if }}</li>{{/each}}</ul>`,
        clipboardText: t('templates.legend.coursesAsHtml'),
      },
      {
        clipboardValue: '{{dueDate}}',
        clipboardText: t('templates.legend.dueDate'),
      },
    ];
  }

  if ([EmailType.COURSE_AVAILABILITY].includes(emailType as EmailType)) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: '{{#each courses}} {{name}} {{/each}}',
        clipboardText: t('templates.legend.coursesAsText'),
      },
      {
        clipboardValue:
          '<ul>{{#each courses}}<li><a href="{{url}}">{{name}}</a></li>{{/each}}</ul>',
        clipboardText: t('templates.legend.coursesAsHtml'),
      },
    ];
  }

  if (
    [EmailType.SECURITY_CULTURE, EmailType.SECURITY_CULTURE_REMINDER].includes(
      emailType as EmailType,
    )
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: EmailPlaceholders.SECURITY_CULTURE_URL,
        clipboardText: t('security.culture.surveyUrl'),
      },
    ];
  }

  if (
    [
      EmailType.INCOMPLETE_COURSE_REMINDER,
      EmailType.COURSE_ENROLLMENT,
      EmailType.SECURITY_CULTURE,
      EmailType.SECURITY_CULTURE_REMINDER,
    ].includes(emailType as EmailType)
  ) {
    clipboardValues = [
      ...clipboardValues,
      {
        clipboardValue: EmailPlaceholders.ACCOUNT_NAME,
        clipboardText: t('templates.legend.accountName'),
      },
    ];
  }

  return clipboardValues;
};
