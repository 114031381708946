import { LanguagesEnum } from '@/api/enums';
import { getExceptionFromAxiosError } from '@/client/helpers';
import { AxiosError, AxiosInstance } from 'axios';
import { CourseFilesType } from '../courses';

const fileService = (client: AxiosInstance) => {
  const getFile = async (
    id: string,
    token?: string,
  ): Promise<File | undefined> => {
    const headers: Record<string, any> = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      const response = await client.get<File>(`files/${id}`, {
        headers,
        withCredentials: true,
      });

      return response.data;
    } catch (e) {
      throw getExceptionFromAxiosError(e as AxiosError);
    }
  };

  const getFileUploadUrl = () => `${client.defaults.baseURL}files`;

  return { getFile, getFileUploadUrl };
};

export default fileService;
