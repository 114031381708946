import { equal, notEqual } from '@/api/helpers';
import { client } from '@/client';
import { EmailPlaceholders, TemplatePlaceholders } from '@/common/constants';
import { handleHandlebarsError } from '@/utils/helpers';
import Handlebars from 'handlebars';

export const checkIfSystemEmailExists = async (payload: {
  type: string;
  account?: { id: string; isSystem: boolean };
  id?: string;
}): Promise<boolean> => {
  let filters = [equal('type', payload.type)];

  if (payload?.account) {
    filters = [
      ...filters,
      equal('account', payload.account?.isSystem ? null : payload.account?.id),
    ];
  }

  if (payload?.id) {
    filters = [...filters, notEqual('id', payload.id)];
  }

  const result =
    await client.systemEmailsTypesConfigsService.getSystemEmailsTypesConfigs({
      filters: filters,
    });

  return result.count > 0;
};

const mockTemplatesPlaceholders = {
  [TemplatePlaceholders.ID]: 'ID',
  [TemplatePlaceholders.FIRST_NAME]: 'FirstName',
  [TemplatePlaceholders.LAST_NAME]: 'LastName',
  [TemplatePlaceholders.POSITION]: 'Position',
  [TemplatePlaceholders.EMAIL]: 'Email',
  [TemplatePlaceholders.SENDER]: 'Sender',
  [TemplatePlaceholders.TRACKING_URL]: 'TrackingURL',
  [TemplatePlaceholders.TRACKER]: 'Tracker',
  [TemplatePlaceholders.URL]: 'URL',
  [TemplatePlaceholders.BASE_URL]: 'BaseURL',
  [TemplatePlaceholders.HONEYPOT_LINK]: 'HoneypotLink',
};

const mockEmailTemplatesPlaceholders = {
  [EmailPlaceholders.FULL_NAME]: 'FullName',
  [EmailPlaceholders.FIRST_NAME]: 'FirstName',
  [EmailPlaceholders.LAST_NAME]: 'LastName',
  [EmailPlaceholders.USERNAME]: 'Username',
  [EmailPlaceholders.EMAIL]: 'Email',
  [EmailPlaceholders.LOGO_URL]: 'LogoUrl',
  [EmailPlaceholders.ACCOUNT_NAME]: 'AccountName',
  [EmailPlaceholders.PLATFORM_NAME]: 'PlatformName',
  [EmailPlaceholders.PLATFORM_PHONE]: 'PlatformPhone',
  [EmailPlaceholders.PLATFORM_EMAIL]: 'PlatformEmail',
  [EmailPlaceholders.LOGIN_URL]: 'LoginUrl',
  [EmailPlaceholders.PASSWORD]: 'Password',
  [EmailPlaceholders.SECURITY_CULTURE_URL]: 'SecurityCultureUrl',
};

export const replacePlaceholders = (
  text: string,
  placeholderValues: Record<string, string>,
): string => {
  for (const placeholder in placeholderValues) {
    text = text.replace(
      new RegExp(placeholder, 'g'),
      placeholderValues[
        placeholder as EmailPlaceholders | TemplatePlaceholders
      ],
    );
  }

  return text;
};

const isHandlebarsTemplateValid = (
  testString: string | undefined,
  toast: any,
  isEmail?: boolean,
) => {
  if (!testString) return true;
  const replacedString = replacePlaceholders(
    testString,
    isEmail ? mockEmailTemplatesPlaceholders : mockTemplatesPlaceholders,
  );

  try {
    Handlebars.precompile(replacedString);
    return true;
  } catch (error) {
    handleHandlebarsError(error as Error, toast);
    return false;
  }
};

export const isNotValidHandlebarsEmailTemplateCheck = (
  html: string,
  toast: any,
  subject?: string,
  text?: string,
  isEmail?: boolean,
): boolean => {
  const isSubjectValid = isHandlebarsTemplateValid(subject, toast, isEmail);
  const isPreviewTextValid = isHandlebarsTemplateValid(text, toast, isEmail);
  const isTemplateValid = isHandlebarsTemplateValid(html, toast, isEmail);

  return !isSubjectValid || !isPreviewTextValid || !isTemplateValid;
};
