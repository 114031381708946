import { FilePurpose } from '@/api/enums';
import { User } from '@/client/users';

export type File = {
  id: string;
  bucket: string;
  remote: string;
  fileName: string;
  mimeType: string;
  size: number;
  extension: string;
  created: Date;
  signedUrl: string;
  updated: Date | null;
  user: User;
  purpose: FilePurpose;
};

export const FileType = {
  VIDEO: 'VIDEO',
  PDF: 'PDF',
  SCORM: 'SCORM',
};

export const FileExtensions = {
  [FileType.VIDEO]: ['.mp4', '.mpeg4'],
  [FileType.PDF]: ['.pdf'],
  [FileType.SCORM]: ['.zip'],
};
