import { handleAxiosError } from '@/api/helpers';
import { Account } from '@/client/accounts';
import { FilterNamesEnum, getFiltersFromColumns } from '@/client/helpers';
import {
  SecurityCultureSurvey,
  SurveyStatus,
} from '@/client/security-culture/types';
import { Actions, Subjects, SystemRoles } from '@/client/users';
import {
  RedirectPaths,
  RedirectPathsEnum,
  TableNamesEnum,
} from '@/common/constants';
import { DialogContext } from '@/common/context';
import {
  DataTable,
  DataTableActions,
  DataTableColumnsMultiselect,
  DataTableColumnType,
  DataTableFilters,
  DataTableToolbar,
  FilterTypeEnum,
} from '@/components/tables/crud';
import {
  useDeleteSurvey,
  useEditSurvey,
  useSurveys,
} from '@/hooks/query/security-culture.hooks';
import { useAppSelector } from '@/hooks/store';
import { useTable } from '@/hooks/table.hook';
import { usePermission } from '@/hooks/usePermission';
import { useToast } from '@/hooks/useToast';
import { selectCurrentAccount } from '@/store/features/account';
import { selectCurrentUser } from '@/store/features/users';
import { DateFormats } from '@/system-settings/enums';
import { AppChip } from '@/ui/chip';
import { FormatDate } from '@/ui/date';
import { FlexContainer } from '@/ui/styled-ui';
import { displayMiltipleItems } from '@/utils/helpers/ui.helper';
import { AxiosError } from 'axios';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type SurveysDatatablePropsType = {
  setShowCreate: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SurveysDatatable: React.FC<SurveysDatatablePropsType> = ({
  setShowCreate,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { can } = usePermission();
  const navigate = useNavigate();
  const currentAccount = useAppSelector(selectCurrentAccount);

  const { skip, take, sort, apiFilters, onFilter, onSort, onPage } = useTable();

  const { setDialogData } = useContext(DialogContext);

  const { isLoading, surveys, refetch } = useSurveys({
    accountId: currentAccount?.id,
    take,
    skip,
    filters: apiFilters,
    sort: sort && sort.length > 0 ? [sort.join(',')] : [],
  });

  useEffect(() => {
    setShowCreate(!surveys?.count || !currentAccount?.isSystem);
  }, [surveys, currentAccount]);

  const onRowClick = (event: DataTableRowClickEvent) => {
    if (event.data.isDemo && !currentAccount?.isSystem) return;
    navigate(
      RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_EDIT_SURVEY](
        event.data.id,
      ),
    );
  };

  const formatTargetColumn = (row: SecurityCultureSurvey) => {
    const targets = Object.values(row?.targets || []);

    return targets?.length ? (
      <div className="align-self-center">
        {displayMiltipleItems(
          targets
            ?.flat()
            ?.map(({ name }) => name)
            ?.join(', '),
          t,
        )}
      </div>
    ) : (
      <div className="align-self-center">{currentAccount?.name}</div>
    );
  };

  const columns: DataTableColumnType[] = [
    {
      field: 'targets',
      header: t('generic.target'),
      sortable: false,
      filterable: false,
      render: (row: SecurityCultureSurvey) => (
        <FlexContainer justify="flex-start" gap={24}>
          {row?.isDemo && (
            <AppChip label={t('security.culture.demo.status')} type="error" />
          )}
          {formatTargetColumn(row)}
        </FlexContainer>
      ),
    },
    ...(currentAccount?.isSystem
      ? []
      : ([
          {
            field: 'date',
            header: t('generic.date'),
            sortable: true,
            filterable: false,
            render: (row: SecurityCultureSurvey) =>
              row?.isDemo ? (
                <span>&#8212;</span>
              ) : row?.date ? (
                <FormatDate
                  date={row?.date}
                  format={DateFormats.FULL_MONTH_YEAR}
                />
              ) : (
                t('generic.never')
              ),
          },
          {
            field: 'status',
            header: t('generic.status'),
            sortable: true,
            filterable: true,
            filters: {
              label: t('generic.status'),
              type: FilterTypeEnum.SELECT_PUBLISHED_DRAFT,
              field: FilterNamesEnum.SURVEY_PUBLISHED,
            },
            render: (row: SecurityCultureSurvey) =>
              row?.isDemo ? (
                <span>&#8212;</span>
              ) : row?.status === SurveyStatus.PUBLISHED ? (
                <AppChip label={t('generic.published')} type="primary" />
              ) : (
                <AppChip label={t('generic.draft')} type="secondary" />
              ),
          },
        ] as DataTableColumnType[])),
    {
      field: 'actions',
      header: t('generic.actions'),
      sortable: false,
      filterable: false,
      style: {
        width: '80px',
        textAlign: 'center',
      },
      render: (row: SecurityCultureSurvey) => (
        <DataTableActions menuItems={menuItems(row)} />
      ),
    },
  ];

  // Set the preselected columns
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  const defaultVisibleColumns = columns.map((column) => column.field);
  const alwaysVisibleColumns = ['targets', 'actions'];
  const toolbar = (
    <DataTableToolbar>
      <FlexContainer
        justify="space-between"
        gap={8}
        align="flex-start"
        wrap="wrap"
      >
        {!currentAccount?.isSystem && (
          <DataTableFilters
            filters={getFiltersFromColumns(columns)}
            onFilter={onFilter}
            tableName={TableNamesEnum.SURVEYS_LIST}
          />
        )}
        <DataTableColumnsMultiselect
          columns={columns}
          tableName={TableNamesEnum.SURVEYS_LIST}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          defaultVisibleColumns={defaultVisibleColumns}
          alwaysVisibleColumns={alwaysVisibleColumns}
        />
      </FlexContainer>
    </DataTableToolbar>
  );

  const deleteSurvey = useDeleteSurvey();
  const handleDeleteSchedule = async (survey: SecurityCultureSurvey) => {
    if (!currentAccount?.id || !survey?.id) return;

    try {
      await deleteSurvey.delete({
        accountId: currentAccount?.id,
        surveyId: survey?.id,
      });
      await refetch();

      toast?.success(t('toast.success'), t('security.culture.survey.deleted'));
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  const menuItems = (survey: SecurityCultureSurvey) => {
    const menu: MenuItem[] = [];

    if (
      can(Actions.UPDATE, Subjects.SECURITY_CULTURE) &&
      (!survey.isDemo || currentAccount?.isSystem)
    ) {
      menu.push({
        label: t('generic.edit'),
        icon: 'pi pi-pencil',
        command: () => {
          navigate(
            RedirectPaths[RedirectPathsEnum.SECURITY_CULTURE_EDIT_SURVEY](
              survey.id as string,
            ),
          );
        },
      });

      if (
        !currentAccount?.isSystem &&
        can(Actions.UPDATE, Subjects.SECURITY_CULTURE)
      ) {
        menu.push({
          label:
            survey?.status === SurveyStatus.DRAFT
              ? t('generic.publish')
              : t('generic.unpublish'),
          icon:
            survey.status === SurveyStatus.DRAFT
              ? 'pi pi-plus-circle'
              : 'pi pi-times-circle',
          command: async () => {
            if (!survey?.id) return;

            const isPublish = survey?.status === SurveyStatus.DRAFT;
            await handleToggleItemsStatus(
              survey,
              isPublish ? SurveyStatus.PUBLISHED : SurveyStatus.DRAFT,
            );
          },
          disabled: !survey.file || !survey.date || !survey.targets,
        });
      }
    }

    if (can(Actions.DELETE, Subjects.SECURITY_CULTURE)) {
      menu.push({
        label: t('generic.delete'),
        icon: 'pi pi-times',
        command: () =>
          setDialogData({
            type: 'confirmation',
            show: true,
            header: t('dialog.delete'),
            message: t('dialog.delete.survey'),
            onAccept: () => handleDeleteSchedule(survey),
          }),
      });
    }

    return menu;
  };

  const editSurvey = useEditSurvey();
  const handleToggleItemsStatus = async (
    survey: SecurityCultureSurvey,
    status: SurveyStatus,
  ) => {
    try {
      await editSurvey.edit({
        accountId: (currentAccount as Account)?.id,
        id: survey.id,
        status,
      });
      await refetch();
      toast?.success(
        t('toast.success'),
        status === SurveyStatus.PUBLISHED
          ? t('security.culture.survey.published')
          : t('security.culture.survey.unpublished'),
      );
    } catch (e) {
      handleAxiosError(e as Error | AxiosError, toast);
    }
  };

  return (
    <>
      <DataTable
        data={surveys?.result}
        count={surveys?.count}
        isLoading={isLoading}
        columns={columns}
        visibleColumns={visibleColumns}
        toolbar={toolbar}
        rows={take}
        skip={skip}
        onPage={onPage}
        onSort={onSort}
        sort={sort}
        onRowClick={
          can(Actions.UPDATE, Subjects.USERS) ? onRowClick : undefined
        }
      />
    </>
  );
};
