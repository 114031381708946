import { LanguagesEnum } from '@/api/enums';
import { handleAxiosError } from '@/api/helpers';
import { client } from '@/client';
import {
  Course,
  CourseAttempt,
  CourseStatusAttemptEnum,
} from '@/client/courses';
import { Subjects, User } from '@/client/users';
import { RedirectPaths, RedirectPathsEnum } from '@/common/constants';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';
import { scormFactory } from '@/scorm/scorm.factory';
import { ScormVersion } from '@/scorm/types';
import { hubspotTrackCourseCompletion } from '@/utils/hubspot';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

type ScormContextProps = {
  values: Record<string, any>;
  errors: Record<string, any>;
};

const ScormContext = createContext<ScormContextProps>({
  values: {},
  errors: {},
});

type ScormProviderProps = {
  children: React.ReactNode;
  version: ScormVersion;
  attempt?: CourseAttempt;
  course: Course;
  user: User;
  setStatus?: (status?: CourseStatusAttemptEnum) => void;
  setScore?: (score?: number) => void;
  setIsSubmittingStatus?: (isSubmittingStatus: boolean) => void;
  setIsBellowThreshold?: (isBellowThreshold: boolean) => void;
  isPreview: boolean;
  isLastModule?: boolean;
  setNextModule?: () => void;
};

export const ScormProvider: React.FC<ScormProviderProps> = ({
  children,
  version,
  attempt,
  course,
  user,
  setStatus,
  setScore,
  setIsSubmittingStatus,
  setIsBellowThreshold,
  isPreview,
  isLastModule,
  setNextModule,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { canUseFeature } = useFeatureFlag();
  const [scormValues, setScormValues] = useState<Record<string, any>>(
    attempt?.payload ?? {},
  );
  const [scormErrors, setScormErrors] = useState<Record<string, any>>({});
  const hasSetSubmittingStatus = useRef<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const saveScormValues = async (
    payload: any,
    status: CourseStatusAttemptEnum,
    timeSpent: number,
    score: number,
  ) => {
    if (!attempt?.course?.id || !attempt.id) {
      return;
    }

    const courseMinimumScore = user?.account?.courseMinimumScore ?? 0;
    const isBellowThreshold =
      status === CourseStatusAttemptEnum.COMPLETED &&
      score < courseMinimumScore &&
      canUseFeature(Subjects.COURSE_MINIMUM_SCORE_REQUIREMENT);
    const statusModified = isBellowThreshold
      ? CourseStatusAttemptEnum.IN_PROGRESS
      : status;

    try {
      if (
        status === CourseStatusAttemptEnum.COMPLETED &&
        attempt.status !== CourseStatusAttemptEnum.COMPLETED &&
        !hasSetSubmittingStatus.current
      ) {
        setIsSubmittingStatus?.(true);
        hasSetSubmittingStatus.current = true;
      }

      setHasUnsavedChanges(true);

      await client.learnerCourses.updateCourseAttempt({
        courseId: attempt.course.id,
        attemptId: attempt.id,
        payload: {
          payload,
          status: statusModified,
          timeSpent,
          score,
        },
      });

      // Update UI status
      setStatus?.(statusModified);
      setScore?.(score);

      if (user?.freeTrialEndsAt) {
        hubspotTrackCourseCompletion(course, statusModified);
      }
    } catch (error) {
      handleAxiosError(error as Error | AxiosError, toast);
    } finally {
      setIsSubmittingStatus?.(false);
      setHasUnsavedChanges(false);

      if (
        canUseFeature(Subjects.SKIP_COURSE_SURVEY) &&
        !isBellowThreshold &&
        status === CourseStatusAttemptEnum.COMPLETED
      ) {
        isLastModule
          ? navigate(
              RedirectPaths[RedirectPathsEnum.LEARNER_DASHBOARD_ACTIVE](),
            )
          : setNextModule?.();
      }

      if (setIsBellowThreshold && isBellowThreshold) {
        setIsBellowThreshold(isBellowThreshold);
      }
    }
  };

  const scormInstance = scormFactory(user, version, {
    onSetValue: (element, value, api) => {
      setScormValues(api);
    },
    onCommit: (
      api: any,
      status: CourseStatusAttemptEnum,
      timeSpent: number,
      score: number,
    ) => {
      if (!isPreview) {
        saveScormValues(api?.cmi, status, timeSpent, score);
      } else {
        setStatus?.(status);
      }
    },
    onInit: (api) => {
      if (!isPreview) {
        api?.loadFromJSON(attempt?.payload ?? {});
      }
    },
    onError: (error) => {
      setScormErrors(error);
      toast?.error(t('error'), error);
    },
  });

  return (
    <ScormContext.Provider value={{ values: scormValues, errors: scormErrors }}>
      {children}
    </ScormContext.Provider>
  );
};

// Custom hook to use the SCORM context
export const useScorm = () => useContext(ScormContext);
